import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BiEditAlt, BiTrash } from 'react-icons/bi'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authenticatedServiceInstance from '../../services/AuthenticatedService';
import RemoveConfirmationModal from '../../components/RemoveConfirmationModal'; // Import the confirmation modal
import ChamberScheduler from '../ChamberScheduler/ChamberScheduler';
import ChamberModal from './ChamberModal';

function Chamber({ id, name }) {

  const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken();
	// const { id, doctorName } = useParams();

	const [chamber, setChamber] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [chamberData, setChamberData] = useState(null);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [selectedChamberToRemove, setSelectedChamberToRemove] = useState(null);
 	// State to indicate whether the modal is in create mode or not
 	const [isCreateMode, setIsCreateMode] = useState(false);
	const [selectedDay, setSelectedDay] = useState(null);
	const [selectedChamber, setSelectedChamber] = useState(null);

	const handleResponse = (value) => {
		// console.log(value)
		if (value === 'save') {
			toast.success("Successfully saved");
		} else if (value === 'update') {
			toast.success("Successfully updated");
		} else if (value === 'error') {
			toast.error("Error occured");
		} else {
			toast.error("Something goes wrong!");
		}
	}
	// Function to handle opening the modal in create mode
  const handleOpenCreateModal = () => {
    setIsCreateMode(true);
    setChamberData(null);
    setIsModalOpen(true);
  };

	const showRemoveConfirmation = (cham) => {
		setSelectedChamberToRemove(cham);
		setShowConfirmation(true);
	};
	
	const hideRemoveConfirmation = () => {
		setSelectedChamberToRemove(null);
		setShowConfirmation(false);
	};

	const handleRemove = async () => {
		if (!selectedChamberToRemove) return;

		try {
			const response = await fetch(`${BASE_URL}/chamber/remove/${selectedChamberToRemove.id}`, {
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.ok) {
				// Remove the chamber from the list
				const updatedChamberList = chamber.filter(cham => cham.id !== selectedChamberToRemove.id);
				setChamber(updatedChamberList);
				hideRemoveConfirmation(); // Close the confirmation modal
			} else {
				alert(response.res);
				console.error('Failed to remove chamber');
			}
		} catch (error) {
			alert(error);
			console.error('Error while removing chamber:', error);
		}
	};

	const handleLinkClick = (data) => {
    setIsModalOpen(true);
		setChamberData(data);
    setIsCreateMode(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsCreateMode(false);
		setChamberData(null);
  };

	const handleUpdateChamber = (updatedChamber) => {
    // Find the index of the updated chamber in the list
		// console.log(chamber)
    const updatedIndex = chamber.findIndex(cham => cham.id === updatedChamber.id);
    if (updatedIndex !== -1) {
      // Create a new array with the updated chamber
      const updatedChamberList = [...chamber];
      // console.log(updatedChamberList)
      updatedChamberList[updatedIndex].chamber_name = updatedChamber.chamber_name;
      updatedChamberList[updatedIndex].co_ordinate = updatedChamber.co_ordinate;
      updatedChamberList[updatedIndex].contact_no = updatedChamber.contact_no;
      updatedChamberList[updatedIndex].contact_no_2 = updatedChamber.contact_no_2;
      updatedChamberList[updatedIndex].district_id = updatedChamber.district_id;
      updatedChamberList[updatedIndex].district_name = updatedChamber.district_name;
      updatedChamberList[updatedIndex].is_active = updatedChamber.is_active;
      updatedChamberList[updatedIndex].max_booking_per_day = updatedChamber.max_booking_per_day;
      updatedChamberList[updatedIndex].pin = updatedChamber.pin;
      updatedChamberList[updatedIndex].reg_fee = updatedChamber.reg_fee;
      updatedChamberList[updatedIndex].state_id = updatedChamber.state_id;
      updatedChamberList[updatedIndex].state_name = updatedChamber.state_name;
      updatedChamberList[updatedIndex].vill = updatedChamber.vill;
      updatedChamberList[updatedIndex].visit_fee = updatedChamber.visit_fee;
      // Update the list of chambers
      setChamber(updatedChamberList);
    } else {
			// If the chamber doesn't exist in the list, it's a new chamber
			// Append the new chamber to the list
			setChamber(prevChamberList => [...prevChamberList, updatedChamber]);
		}
  };

	useEffect(() => {
			
		const fetchChambersByDoctor = async () => {
			const BASE_URL = process.env.REACT_APP_BASE_URL;

			try {
				const response = await fetch(`${BASE_URL}/chamber/findByDoctorId/${id}`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				setChamber(data);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
				setIsLoading(false);
			}
		};
		fetchChambersByDoctor();
  }, [id, token]);

	const handleShowSchedule = (dayName, chamberId) => {
		setSelectedChamber(chamberId);
		if (selectedDay === dayName) {
			if (selectedDay) {
				setSelectedDay(null);	
			} else {
				setSelectedDay(dayName);
			}
		} else {
			setSelectedDay(dayName);
		}
	}

	return (
		<div className='flex'>			
			{/* <Sidebar /> */}

			<div className='w-full pt-1 pb-10 border-l-2 border-r-2'>
				<div className='flex flex-col justify-between'>
					

					<div className='flex flex-col w-full'>
						<div className="flex justify-end">
							
						</div>
						<table className="min-w-full bg-white table-auto border-collapse">
							<thead>
								<tr className=' sticky top-0'>
									<th className="px-4 border-b text-sm font-medium text-slate-500 rounded-tl-xl text-left">Chamber</th>
									<th className="px-4 border-b text-sm font-medium text-slate-500 text-end"></th>
									<th>
										<button
											className='px-4 py-2 text-sm font-medium text-white bg-pale-green-500 rounded-md hover:bg-pale-green-600 focus:outline-none focus:ring focus:ring-pale-green-300'
											onClick={handleOpenCreateModal}
										>
											Create
										</button>
									</th>
								</tr>
							</thead>
							<tbody>
								{ isLoading && Array.from({ length: 2 }, (_, index) => (
									<tr key={index}>
										<td colSpan={3} className='pt-2'>
											<div className="shimmer"></div>
										</td>
									</tr>
								))}
								{chamber.map((cham) => (
									<React.Fragment key={cham.id}>
									<tr key={cham.id} className='border-b hover:bg-slate-100'>
										<td className='py-2 px-4 text-xs'>
											{cham.chamber_name} 
											<span className='ml-10'>
											{cham.chamber_available.map((available) => (
												<React.Fragment key={available.day_name}>
												<span 
													key={available.day_name}
													className={`ml-3 font-bold cursor-pointer ${
														available.available === 0 ? 'text-red-500' : 'text-green-500'
													}`}
													onClick={() => handleShowSchedule(available.day_name, cham.id)}
													>
													{available.day_name_short}
												</span>
												
												</React.Fragment>
											))}
											</span>
										</td>
										<td className='link justify-end mr-3 mt-1'>
											<Link to={`/chamber/absent-manager/${id}/${cham.id}/${name}/${cham.chamber_name}`}
												className='px-5 text-red-500'>
												Absent manager
											</Link>
										</td>
										<td className='link flex justify-end mr-5 mt-1'>
											<BiEditAlt 
												size={23} 
												onClick={() => handleLinkClick(
													{
														chamberId: cham.id,
														chamberName: cham.chamber_name,
														stateId: cham.state_id,
														districtId: cham.district_id,
														isActive: cham.is_active,
														maxBooking: cham.max_booking_per_day,
														pin: cham.pin,
														regFee: cham.reg_fee,
														vill: cham.vill,
														visitFee: cham.visit_fee,
														contactNo: cham.contact_no,
														contactNo2: cham.contact_no_2
													}
												)} 
											/>
											<BiTrash
												size={23}
												className="ml-5 text-red-600 cursor-pointer"
												onClick={() => showRemoveConfirmation(cham)}
											/>
										</td>
									</tr>
									{selectedDay && selectedChamber === cham.id && (
										<tr className={selectedDay && selectedChamber === cham.id ? `shadow-md transition duration-300 relative z-20` : `pt-2`}>
											<td colSpan={5}>
											<ChamberScheduler
												doctor_id={id}
												chamber_id={cham.id}
												day={selectedDay}
												/> 
											</td>
										</tr>
									)}
									</React.Fragment>
								))}
							</tbody>
						</table>
						<ChamberModal
							isOpen={isModalOpen} 
							onClose={handleCloseModal} 
							data={chamberData}
							onUpdate={handleUpdateChamber}
							isCreateMode={isCreateMode}
							doctorId={id} 
							response={handleResponse}
						/>
					</div>
				</div>
			</div>

			<RemoveConfirmationModal
				value="Chamber"
        isOpen={showConfirmation}
        onCancel={hideRemoveConfirmation}
        onConfirm={handleRemove}
			/>

			<ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
		</div>
	);
}

export default Chamber;