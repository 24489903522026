import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import authenticatedServiceInstance from '../services/AuthenticatedService';

function ChangePassword() {

  const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken(); // Replace with your actual Bearer token

  const [values, setValues] = useState({
    new_password: "",
    confirm_password: "",
  });

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [passwordLengthError, setPasswordLengthError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [msgError, setMsgError] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState(false);

  const handleChange = (event) => {
    setMsgSuccess(false)
    setMsgError(false)
    setValues({
      ...values, [event.target.name]: event.target.value,
    })
  }

  const changePassword = (event) => {
    event.preventDefault();
    fetch(`${BASE_URL}/authentication/changePassword`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        new_password: values.new_password,
      })
    }).then((response) => response.json())
    .then((data) => {
      if (data.status) {
        setMsgSuccess(true);
        setValues({
          ...values, 
          new_password: "",
          confirm_password: "",
        })
      } else {
        setMsgError(true);
      }
    })
  }

  useEffect(() => {
    if (values.new_password.length >= 4) {
      if (values.new_password !== values.confirm_password) {
        setButtonDisabled(true);
        setPasswordMatchError(true);
      } else {
        setButtonDisabled(false);
        setPasswordMatchError(false);
      }
      setPasswordLengthError(false);
    } else {
      setButtonDisabled(true);
      if (values.new_password.length > 0)
        setPasswordLengthError(true);
    }
  }, [values])
      
  return (
    <div className="flex">
      <Sidebar />
      <div className="w-10/12 p-4">
        <p className='text-2xl'>Change Password</p>
        <div className='mt-5'>
          <form action="">

              {!passwordLengthError && passwordMatchError && (
                <div className='mb-2 p-2 bg-red-100 w-2/6'>
                  Confirm password not matched!
                </div>
              )}

              {!msgSuccess && passwordLengthError && (
                <div className='mb-2 p-2 bg-red-100 w-2/6'>
                  Minimum password length should be 4
                </div>
              )}

              {msgSuccess && (
                <div className='mb-2 p-2 bg-green-100 w-2/6'>
                  Password successfully changed
                </div>
              )}

              {msgError && (
                <div className='mb-2 p-2 bg-red-100 w-2/6'>
                  Something goes wrong! Please try again.
                </div>
              )}
            <div>
              <label htmlFor="">New password</label>
              <input 
                id="new_password"
                name='new_password'
                required
                type="password" 
                className='p-2 mt-1 appearance-none relative block border border-grey-600 placeholder-slate-200 rounded text-slate-700 focus:outline-none focus:border-green-500 focus:ring-green-500 w-64'
                placeholder='new password'
                onChange={handleChange}
                value={values.new_password}
              />
            </div>

            <div className='mt-3'>
              <label htmlFor="">Confirm new password</label>
              <input 
                id='confirm_password'
                name='confirm_password'
                required
                type="password" 
                className='p-2 mt-1 appearance-none relative block border border-grey-600 placeholder-slate-200 rounded text-slate-700 focus:outline-none focus:border-green-500 focus:ring-green-500 w-64'
                placeholder='confirm new password'
                onChange={handleChange}
                value={values.confirm_password}
              />
            </div>

            <div className='mt-3'>
              <button 
                type='submit'
                className={`px-3 py-2 w-64 rounded focus:outline-none
                  ${
                    buttonDisabled
                      ? 'bg-slate-400 cursor-not-allowed'
                      : 'bg-pale-green-300 text-white focus:bg-pale-green-500 hover:bg-pale-green-500'
                  }
                `}
                disabled={buttonDisabled}
                onClick={changePassword}
                >
                Change password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;