import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import HospitalTable from './HospitalTable';

function Hospitals() {

	useEffect(() => {
		document.title = "Hospital | Impilo";
	});

	const [searchKeywords, setSearchKeywords] = useState('');
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

	const handleSearchInputChange = (e) => {
		setSearchKeywords(e.target.value);
	};

	const handleCreateModal = (value) => {
    setIsCreateModalOpen(value);
  };

	return (
		<div className='flex'>			
			<Sidebar />

			<div className='w-10/12 p-5'>
				<div className='flex justify-between items-center'>
					<div className='w-2/12'>
						<p className='text-2xl font-medium'>Hospitals</p>
					</div>
					<div className='w-8/12 flex justify-end'>
						<input
							type="text"
							className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-500 focus:w-full"
							placeholder="Search.."
							value={searchKeywords}
							onChange={handleSearchInputChange}
						/>
					</div>

					<div className="w-2/12 flex justify-end">
						<button
							className='px-4 py-3 text-sm font-medium text-white bg-pale-green-500 rounded-md hover:bg-pale-green-600 focus:outline-none focus:ring focus:ring-pale-green-300'
							onClick={() => {
								setIsCreateModalOpen(true);
							}}
						>
							Add new hospital
						</button>
					</div>
				</div>

				<HospitalTable 
					searchKeywords={searchKeywords} 
					isCreateModalOpen={isCreateModalOpen}
					isCreateModalClose={handleCreateModal}
				/>
			</div>
		</div>
	);
}

export default Hospitals;