import React, { useState, useEffect, useCallback } from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import authenticatedServiceInstance from '../../services/AuthenticatedService';

const ChamberModal = ({ isOpen, onClose, data, onUpdate, isCreateMode, doctorId, response }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken();
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const initValues = 
    {
      id: 0,
      chamber_name: "",
      state_id: "",
      district_id: "",
      is_active: "1", // Default to active for new chambers
      max_booking_per_day: "",
      pin: "",
      reg_fee: "",
      vill: "",
      visit_fee: "",
      doctor_id: "",
      contact_no: "",
      contact_no_2: ""
    };
  const [values, setValues] = useState(initValues);

  const handleChange = (event) => {
    const fieldName = event.target.name;

    // Clear the validation error for the changed field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: '',
    }));

    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  }

  const fetchDistricts = useCallback(async (stateId) => {
    // console.log(stateId)
    try {
      const response = await fetch(`${BASE_URL}/location/findDistrictByid/${stateId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch states');
      }
      const res = await response.json();
      setDistricts(res);

      if (isCreateMode) {
        if (res.length > 0 && res[0].id) {
          setValues({ // Make sure to keep the other values unchanged
            id: 0,
            chamber_name: "",
            state_id: stateId,
            district_id: res[0].id,
            is_active: "1", // Default to active for new chambers
            max_booking_per_day: "",
            pin: "",
            reg_fee: "",
            vill: "",
            visit_fee: "",
            doctor_id: doctorId,
            contact_no: "",
            contact_no_2: ""
          });
        }
      }

    } catch (error) {
      console.error(error);
    }
  }, [isCreateMode, doctorId, BASE_URL, token]);

  useEffect(() => {
    // console.log(isCreateMode)
    // console.log(data)
    
    if (data !== null) {
      setValues({
        id: data.chamberId,
        chamber_name: data.chamberName,
        state_id: data.stateId,
        district_id: data.districtId,
        is_active: data.isActive,
        max_booking_per_day: data.maxBooking,
        pin: data.pin,
        reg_fee: data.regFee,
        vill: data.vill,
        visit_fee: data.visitFee,
        contact_no: data.contactNo,
        contact_no_2: data.contactNo2
      });
    }

    if (isCreateMode) {
      // console.log('create mode')
      setValues({
        id: 0,
        chamber_name: "",
        state_id: "",
        district_id: "",
        is_active: "1", // Default to active for new chambers
        max_booking_per_day: "",
        pin: "",
        reg_fee: "",
        vill: "",
        visit_fee: "",
        doctor_id: "",
        contact_no: "",
        contact_no_2: ""
      });
    }

    // Function to fetch data from the API
    const fetchStates = async () => {
      try {
        const response = await fetch(`${BASE_URL}/location/findAllState`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch states');
        }
        const data = await response.json();
        setStates(data);
        if (data.length === 1) {
          // console.log(data[0].id)
          fetchDistricts(data[0].id);
        }
      } catch (error) {
        console.error(error);
      }
    };

    // Fetch data from the API when the component mounts
    if (isOpen) {
      fetchStates();
    }
  }, [isCreateMode, data, isOpen, BASE_URL, token, fetchDistricts]);

  useEffect(() => {
    // console.log(data)
   
    // Fetch data from the API when the component mounts or when stateId changes
    if (data !== null) {
      fetchDistricts(data.stateId);
    }
  }, [data, fetchDistricts]);

  
  const submit = (event) => {
    // console.log(values)
    event.preventDefault();
    let uri = "";

    // Clear any previous validation errors
    setValidationErrors({});

    // Validate each field
    const errors = {};

    if (!values.chamber_name) {
      errors.chamber_name = 'Chamber name is required';
    }
    // console.log(errors);
    if (Object.keys(errors).length > 0) {
      // Update the validation errors state
      setValidationErrors(errors);
      return;
    }

    // console.log(values.id)
    if (values.id === 0) {
      uri = `${BASE_URL}/chamber/save`;
    } else {
      uri = `${BASE_URL}/chamber/update`;
    }

    const updatedChamber = { ...values }; // Create a copy of the current chamber values
    
    fetch(uri, {
      method: values.id === 0 ? 'POST' : 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values)
    }).then((response) => response.json())
    .then((data) => {
      if (data.status) {
        // setMsgSuccess(true);
        if (values.id === 0) {
          response("save");
        } else {
          response("update");
        }
        setValues({
          ...values, 
          id: 0,
          chamber_name: "",
          state_id: "",
          district_id: "",
          is_active: "",
          max_booking_per_day: "",
          pin: "",
          reg_fee: "",
          vill: "",
          visit_fee: "",
          contact_no: "",
          contact_no_2: ""
        })
        onClose();
        onUpdate(updatedChamber);
      } else {
        // setMsgError(true);
        response("error");
      }
    })
  }
  
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? 'visible' : 'invisible'
      }`}
    >
      {/* Black shadow */}
      <div
        className={`fixed inset-0 bg-black opacity-50 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={onClose}
      ></div>

      <div className="bg-white p-5 rounded shadow-md relative t-0 w-full md:w-1/2">
        <div className="flex justify-between">
          <h2 className="text-xl font-bold mb-4">Chamber</h2>
          <span onClick={onClose} className="link pt-0">
            <AiOutlineClose size={23} />
          </span>
        </div>

        <div className="w-full overflow-auto max-h-96">
          <form action="">

            <input type="hidden"
              name="id"
              value={values.chamberId} />

            <div className="flex flex-wrap mb-5">
              <div className="w-full px-4 mt-3">
                <label htmlFor="">Chamber name</label>
                <input
                  type="text"
                  name="chamber_name"
                  value={values.chamber_name}
                  className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                  onChange={handleChange}
                />
                {validationErrors.chamber_name && <p className="text-red-500">{validationErrors.chamber_name}</p>}
              </div>

              <div className="w-full md:w-1/2 px-4 mt-3">
                <label htmlFor="">Reg. fee</label>
                <input
                  type="number"
                  min={0.0}
                  name="reg_fee"
                  value={values.reg_fee}
                  className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                  onChange={handleChange}
                />
              </div>

              <div className="w-full md:w-1/2 px-4 mt-3">
                <label htmlFor="">Visit fee</label>
                <input
                  type="number"
                  min={0.0}
                  name="visit_fee"
                  value={values.visit_fee}
                  className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                  onChange={handleChange}
                />
              </div>

              <div className='w-full mt-3 px-4'>
                <label htmlFor="">Vill</label>
                <input 
                  type="text"
                  name="vill"
                  value={values.vill}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">State</label>
                <select 
                  name="state_id"
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.state_id}
                  onChange={handleChange}
                >
                  {/* Populate the options from the states data */}
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.state_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">District</label>
                <select 
                  name="district_id"
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.district_id}
                  onChange={handleChange}
                >
                  {/* Populate the options from the districts data */}
                  {districts.map((districts) => (
                    <option key={districts.id} value={districts.id}>
                      {districts.district_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Pin</label>
                <input 
                  type="text"
                  name="pin"
                  value={values.pin}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Active</label>
                <select 
                  name="is_active"
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.is_active}
                  onChange={handleChange}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>

              <div className='w-full mt-3 px-4'>
                <label htmlFor="">Max booking per day</label>
                <input 
                  type="number" 
                  min={0.0}
                  name="max_booking_per_day"
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.max_booking_per_day}
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Contact no.</label>
                <input 
                  type="text" 
                  name="contact_no"
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.contact_no}
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Whatsapp</label>
                <input 
                  type="text" 
                  name="contact_no_2"
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.contact_no_2}
                  onChange={handleChange}
                />
              </div>
            </div>

          </form>
        </div>

        <div className='flex justify-between'>
          <button className="mt-4 px-4 py-2 text-pale-green-700 rounded" onClick={onClose}>
            Close
          </button>
          <button className="mt-4 px-4 py-2 bg-pale-green-500 text-white rounded"
            onClick={submit}
            >
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChamberModal;
