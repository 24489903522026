import React from 'react';
import Sidebar from '../components/Sidebar';

function Users() {

  return (
    <div className="flex">
      <Sidebar />
      <div className="w-10/12">Users</div>
    </div>
  );
}

export default Users;