import React, { useEffect, useState } from 'react';
import {BiEditAlt, BiTrash} from 'react-icons/bi'; 
import RemoveConfirmationModal from '../../components/RemoveConfirmationModal'; // Import the confirmation modal
import authenticatedServiceInstance from '../../services/AuthenticatedService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MasterHospitalServiceModal from './MasterHospitalServiceModal';

const MasterHospitalServiceTable = ({ isCreateModalOpen, isCreateModalClose }) => {

	const token = authenticatedServiceInstance.getToken(); // Replace with your actual Bearer token
	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [isCreateMode, setIsCreateMode] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [serviceData, setServiceData] = useState(false);
	const [services, setServices] = useState([]);
	const [selectedToRemove, setSelectedToRemove] = useState(null);
	const [showConfirmation, setShowConfirmation] = useState(false);

	useEffect(() => {
		// console.log(isCreateModalOpen)
		if (isCreateModalOpen) {
			handleOpenCreateModal();
		}
  }, [isCreateModalOpen]);

	useEffect(() => {
		const fetchServices = async () => {
			try {
				const response = await fetch(`${BASE_URL}hospitalServices/getAll`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				// console.log(data)
				setServices(data);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
				setIsLoading(false);
			}
		}
		fetchServices();
	}, [token, BASE_URL]);

	const handleResponse = (value) => {
		// console.log(value)
		if (value === 'save') {
			toast.success("Successfully saved");
		} else if (value === 'update') {
			toast.success("Successfully updated");
		} else if (value === 'error') {
			toast.error("Error occured");
		} else {
			toast.error("Something goes wrong!");
		}
	}

	const handleOpenCreateModal = () => {
    setIsCreateMode(true);
    setServiceData(false);
    setIsModalOpen(true);
  };

	const handleLinkClick = (data) => {
		// console.log(data)
    setIsModalOpen(true);
		setServiceData(data);
    setIsCreateMode(false);
  };

	const handleCloseModal = () => {
    setIsModalOpen(false);
		setServiceData(false);
    setIsCreateMode(false);
		isCreateModalClose(false);
  };

	const handleUpdate = (updatedData) => {
    // Find the index of the updated chamber in the list
    const updatedIndex = services.findIndex(service => service.id === updatedData.id);
    
    if (updatedIndex !== -1) {
      // Create a new array with the updated chamber
      const updatedList = [...services];
      updatedList[updatedIndex] = updatedData;
      
      // Update the list of chambers
      setServices(updatedList);
    } else {
			// If the chamber doesn't exist in the list, it's a new chamber
			// Append the new chamber to the list
			setServices(prevList => [...prevList, updatedData]);
		}
  };


  const showRemoveConfirmation = (service) => {
		console.log(service)
		setSelectedToRemove(service);
		setShowConfirmation(true);
	};
	
	const hideRemoveConfirmation = () => {
		setSelectedToRemove(null);
		setShowConfirmation(false);
	};

	const handleRemove = async () => {
		if (!selectedToRemove) return;

		try {
			const response = await fetch(`${BASE_URL}/hospitalServices/remove/${selectedToRemove.id}`, {
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.ok) {
				// Remove the chamber from the list
				const updatedList = services.filter(service => service.id !== selectedToRemove.id);
				setServices(updatedList);
				hideRemoveConfirmation(); // Close the confirmation modal
			} else {
				alert(response.res);
				console.error('Failed to remove service');
			}
		} catch (error) {
			alert(error);
			console.error('Error while removing hospital:', error);
		}
	};

  return (
		<div className='mt-5 overflow-auto h-[34rem]'>
			<table className="min-w-full bg-white table-auto border-collapse">
				<thead>
					<tr className='bg-slate-100 sticky top-0'>
						<th className="py-3 px-4 border-b text-sm font-bold text-slate-500 rounded-tl-xl text-start">Service</th>
						<th className="py-3 px-4 border-b text-sm text-slate-500"></th>
					</tr>
				</thead>
				<tbody>
					{ isLoading && Array.from({ length: 5 }, (_, index) => (
						<tr key={index}>
							<td colSpan={2} className='pt-2'>
								<div className="shimmer"></div>
							</td>
						</tr>
					))}

					{services.length === 0 ? (
						<tr>
							<td colSpan="2" className="text-center py-4 text-xs">
								No data available
							</td>
						</tr>
					) : (
						services.map((service) => (
							<tr key={service.id} className='border-b hover:bg-slate-100'>
								<td className='py-2 px-4 text-xs'>{service.service_name}</td>
								<td className='link flex justify-end mr-3 mt-1'>
									<BiEditAlt
										size={23} 
										onClick={() => handleLinkClick(service)}  
										/>
									<BiTrash
										size={23}
										className="ml-5 text-red-600 cursor-pointer"
										onClick={() => showRemoveConfirmation(service)}
									/>
								</td>
							</tr>
						))
					)}
				</tbody>
			</table>

			<MasterHospitalServiceModal
				isOpen={isModalOpen} 
				onClose={handleCloseModal} 
				data={serviceData}
				onUpdate={handleUpdate}
				isCreateMode={isCreateMode}
				response={handleResponse}
			/>

			<RemoveConfirmationModal
				value="Service"
        isOpen={showConfirmation}
        onCancel={hideRemoveConfirmation}
        onConfirm={handleRemove}
      />

			<ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
		</div>
  );
};

export default MasterHospitalServiceTable;
