import React, { useState } from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import authenticatedServiceInstance from '../../services/AuthenticatedService';

const AbsentModal = ({ isOpen, onClose, response, doctorId, chamberId, onUpdate }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken();
  const [validationErrors, setValidationErrors] = useState({});

  const [values, setValues] = useState([]);

  const handleChange = (event) => {
    const fieldName = event.target.name;

    // Clear the validation error for the changed field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: '',
    }));

    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  }
  
  const submit = (event) => {
    // console.log(values)
    event.preventDefault();
    let uri = "";

    // Clear any previous validation errors
    setValidationErrors({});

    // Validate each field
    const errors = {};

    // console.log(errors);
    if (Object.keys(errors).length > 0) {
      // Update the validation errors state
      setValidationErrors(errors);
      return;
    }

    uri = `${BASE_URL}/absentManager/save`;
    
    const updatedList = { ...values }; // Create a copy of the current chamber values

    updatedList['doctor_id'] = doctorId
    updatedList['chamber_id'] = chamberId
    // console.log(updatedList)
    
    fetch(uri, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedList)
    }).then((response) => response.json())
    .then((data) => {
      if (data.status) {
        // setMsgSuccess(true);
        if (values.id === 0) {
          response("save");
        }
        
        onClose();
        onUpdate(updatedList);
      } else {
        // setMsgError(true);
        response("error");
      }
    })
  }
  
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? 'visible' : 'invisible'
      }`}
    >
      {/* Black shadow */}
      <div
        className={`fixed inset-0 bg-black opacity-50 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={onClose}
      ></div>

      <div className="bg-white p-5 rounded shadow-md relative t-0 w-full md:w-1/2">
        <div className="flex justify-between">
          <h2 className="text-xl font-bold mb-4">Chamber</h2>
          <span onClick={onClose} className="link pt-0">
            <AiOutlineClose size={23} />
          </span>
        </div>

        <div className="w-full overflow-auto max-h-96">
          <form action="">

            <div className="flex flex-wrap mb-5">
              <div className="w-full px-4 mt-3">
                <label htmlFor="">Absent date</label>
                <input
                  type="date"
                  name="absent_date"
                  value={values.date}
                  className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                  onChange={handleChange}
                />
                {validationErrors.date && <p className="text-red-500">{validationErrors.date}</p>}
              </div>

            </div>

          </form>
        </div>

        <div className='flex justify-between'>
          <button className="mt-4 px-4 py-2 text-pale-green-700 rounded" onClick={onClose}>
            Close
          </button>
          <button className="mt-4 px-4 py-2 bg-pale-green-500 text-white rounded"
            onClick={submit}
            >
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default AbsentModal;
