import React from 'react';
import {AiOutlineClose} from 'react-icons/ai';

const ImageViewModal = ({isOpen, onClose, data, type}) => {
  // console.log(data)
	const BASE_URL = process.env.REACT_APP_BASE_URL;
  if (!data) return;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? 'visible' : 'invisible'
      }`}
    >
      {/* Black shadow */}
      <div
        className={`fixed inset-0 bg-black opacity-50 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={onClose}
      ></div>

      <div className="bg-white p-5 rounded shadow-md relative t-0 w-full md:w-1/2 max-h-full overflow-y-auto">
        <div className="flex justify-between">
          <h2 className="text-xl font-bold mb-4">View { type }</h2>
          <span onClick={onClose} className="link pt-0">
            <AiOutlineClose size={23} />
          </span>
        </div>

        <div className="w-full">
          <img src={BASE_URL + data} alt="" />
        </div>
      </div>

    </div>
  );
};

export default ImageViewModal;
