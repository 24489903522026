import authenticatedServiceInstance from '../../../services/AuthenticatedService';

const cancelOpd = async (id, cancel_reason) => {
  if (!id) return;

	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken(); // Replace with your actual Bearer token
  const values = {"id": id, "cancel_reason": cancel_reason};
  try {
    const response = await fetch(`${BASE_URL}opdManager/cancel`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(values)
    });
    if (!response.ok) {
      throw new Error('Failed to cancelled');
    }
    return await response.json();
   
  } catch (error) {
    console.error(error);
		throw new Error(error.message);
  }
};

export {cancelOpd};
