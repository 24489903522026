import React from 'react';

const RemoveConfirmationModal = ({ value, isOpen, onCancel, onConfirm }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50" onClick={onCancel}></div>
      <div className="bg-white p-5 rounded shadow-md relative t-0 w-full md:w-1/2">
        <h2 className="text-xl font-bold mb-4">Confirm {value} Removal</h2>
        <p className="mb-4">Are you sure you want to remove this {value}?</p>
        <div className="flex justify-end">
          <button className="mr-2 px-4 py-2 bg-red-500 text-white rounded" onClick={onConfirm}>
            Yes
          </button>
          <button className="px-4 py-2 bg-gray-300 text-gray-700 rounded" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveConfirmationModal;
