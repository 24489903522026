import React from 'react';
import Sidebar from '../components/Sidebar';

function Dashboard() {

  return (
    <div className='flex'>
      <Sidebar />
      <div className='w-10/12'>Dashboard</div>
    </div>
  );
}

export default Dashboard;