import React, { useState } from 'react';
import Sidebar from '../../components/Sidebar';
import AutocompleteSelect from '../../components/Autocompleteselect';
import {fetchHospitalInquiryData} from './api/HospitalInquiryApi';
import HospitalInquiryTable from './layouts/HospitalInquiryTable';

function HospitalInquiry() {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [dateFrom, setDateFrom] = useState('');
	const [dateTo, setDateTo] = useState('');
	const [mobileNo, setMobileNo] = useState('');
	const [hospitalId, setHospitalId] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [tableData, setTableData] = useState([]);

	const apiEndpoint = `${BASE_URL}hospital/getAllHospital`;

  const search = async () => {
		setIsLoading(true);
		let inquiryData = await fetchHospitalInquiryData(dateFrom, dateTo, mobileNo, hospitalId);
		setIsLoading(false);
    // console.log(inquiryData)
		setTableData(inquiryData);

  }
  
  return (
    <div className='flex'>
      <Sidebar />
      <div className='w-10/12 p-5'>
				<div className='flex flex-col'>

					{/* OPD TITLE ON THE LEFT */}
					<div className='w-full mb-3'>
						<p className='text-2xl font-medium'>Hospital Inquiry</p>
					</div>

					<div className='flex flex-wrap justify-between items-center'>
						{/* THE OTHER FILTER AND SEARCH BUTTON ON THE RIGHT  */}
						<div className="w-full flex flex-wrap">

							<div className='flex flex-col w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2'>
								<label htmlFor="">Date from</label>
								<input
									type="date"
									value={dateFrom}
									className='font-medium h-10 border border-pale-green-300 hover:border-pale-green-500 focus:outline-none focus:border-pale-green-500 rounded p-1'
									onChange={(e) => setDateFrom(e.target.value)}
								/>
							</div>

							<div className='flex flex-col w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2'>
								<label htmlFor="">Date to</label>
								<input
									type="date"
									value={dateTo}
									className='font-medium h-10 border border-pale-green-300 hover:border-pale-green-500 focus:outline-none focus:border-pale-green-500 rounded p-1'
									onChange={(e) => setDateTo(e.target.value)}
								/>
							</div>

							<div className='flex flex-col w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2'>
								<label htmlFor="">Patient's mobile no.</label>
								<input
									type='text'
									className='font-medium h-10 border border-pale-green-300 hover:border-pale-green-500 focus:outline-none focus:border-pale-green-500 rounded p-1'
									placeholder='mobile no.'
									value={mobileNo}
									onChange={(e) => setMobileNo(e.target.value)}
								/>
							</div>

            
              <div className='flex flex-col w-4/12'>
                <label htmlFor="">Hospital</label>
                <AutocompleteSelect
                  fetchData={apiEndpoint}
                  width=""
                  placeholder="Hospitals"
                  selectValue={hospitalId}
                  setSelectValue={setHospitalId}
                />
              </div>


              <div className='flex flex-col w-2/12'>
                <label htmlFor="">&nbsp;</label>
                <button
                  className='ml-2 px-5 py-2 text-md font-medium text-white bg-pale-green-500 rounded hover:bg-pale-green-600 focus:outline-none focus:ring focus:ring-pale-green-300'
                  onClick={() => {
                    search();
                  }}
                  >
                  Search
                </button>
              </div>
						</div>
					</div>
					<HospitalInquiryTable
						isLoading={isLoading}
						tableData={tableData}
						search={search}
					/>
				</div> {/* END FLEX COL */}
				
			</div>
    </div>
  );
}

export default HospitalInquiry;