import React from 'react';
import {AiOutlineClose} from 'react-icons/ai';

const OpdCancelViewModal = ({isOpen, onClose, data, onUpdate}) => {

  if (!data) return;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? 'visible' : 'invisible'
      }`}
    >
      {/* Black shadow */}
      <div
        className={`fixed inset-0 bg-black opacity-50 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={onClose}
      ></div>

      <div className="bg-white p-5 rounded shadow-md relative t-0 w-full md:w-1/2 max-h-full overflow-y-auto">
        <div className="flex justify-between">
          <h2 className="text-xl font-bold mb-4">Cancel appointment of { data.m_name }</h2>
          <span onClick={onClose} className="link pt-0">
            <AiOutlineClose size={23} />
          </span>
        </div>

        <div className="w-full">
          <form action="">

            <div className="flex flex-wrap mb-5">
              <label htmlFor="">Reason for cancellation</label>
              <input
                type="text"
                name="cancel_reason"
                value={data.cancel_reason}
                className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                onChange={null}
                disabled={true}
              />
            </div>

            <div className="flex flex-wrap mb-5">
              <label htmlFor="">Cancelled date time</label>
              <input
                type="text"
                name="cancel_datetime"
                value={data.cancel_datetime}
                className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                onChange={null}
                disabled={true}
              />
            </div>

            <div className="flex flex-wrap mb-5">
              <label htmlFor="">Cancelled by</label>
              <input
                type="text"
                name="cancelled_by"
                value={data.cancelled_by}
                className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                onChange={null}
                disabled={true}
              />
            </div>

            <div className='flex justify-between'>
              <button className="mt-4 px-4 py-2 text-pale-green-700 rounded"
                onClick={onClose}
                tabIndex={-1}
              >
                Close
              </button>
            </div>
            
          </form>
        </div>
      </div>

    </div>
  );
};

export default OpdCancelViewModal;
