import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';

const Sidebar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(prevState => !prevState);
  };
  
  return (
    <div className={`bg-slate-100 text-grey-500 h-screen flex flex-col w-2/12 sm:w-1/4 md:w-1/5 lg:w-1/6 ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
      <div className="flex items-center justify-center h-16 border-b border-gray-400">
        <h1 className="text-xl">Admin</h1>
        <button onClick={toggleMobileMenu} className="sm:hidden px-4 py-2 flex items-start h-full">
          <FiMenu size={30} />
        </button>
      </div>
      <nav className="flex-1 p-4">
        <ul className="space-y-2">
          <li>
            <Link
              to="/dashboard"
              className="block py-2 px-4 rounded transition duration-200 hover:bg-slate-200"
            >
              Dashboard
            </Link>
          </li>

          <li>
            <Link
              to="/doctors"
              className="block py-2 px-4 rounded transition duration-200 hover:bg-slate-200"
            >
              Doctors
            </Link>
          </li>

          <li>
            <Link
              to="/hospitals"
              className="block py-2 px-4 rounded transition duration-200 hover:bg-slate-200"
            >
              Hospitals
            </Link>
          </li>

          <li>
            <Link
              to="/ambulance"
              className="block py-2 px-4 rounded transition duration-200 hover:bg-slate-200"
            >
              Ambulance
            </Link>
          </li>

          <li>
            <Link
              to="/opdManager"
              className="block py-2 px-4 rounded transition duration-200 hover:bg-slate-200"
            >
              OPD manager
            </Link>
          </li>

          <li>
            <Link
              to="/hospitalInquiry"
              className="block py-2 px-4 rounded transition duration-200 hover:bg-slate-200"
            >
              Hospital Inquiry
            </Link>
          </li>

          <li>
            <Link
              to="/subscriptions"
              className="block py-2 px-4 rounded transition duration-200 hover:bg-slate-200"
            >
              Subscription
            </Link>
          </li>

          <li>
            <Link
              to="/users"
              className="block py-2 px-4 rounded transition duration-200 hover:bg-slate-200"
            >
              User
            </Link>
          </li>

          <li className='border-b border-slate-400'></li>

          <li>
            <Link
              to="/changePassword"
              className="block py-2 px-4 rounded transition duration-200 hover:bg-slate-200"
            >
              Change Password
            </Link>
          </li>

          <li>
            <Link
              to="/logout"
              className="block py-2 px-4 rounded transition duration-200 hover:bg-slate-200"
            >
              Logout
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;