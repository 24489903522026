import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import HospitalServiceTable from './HospitalServiceTable';

function HospitalServices() {
	const [ hospitalId, setHospitalId ] = useState(null);
	const { id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Hospital Services";
		// console.log(hospitalId)
	}, [hospitalId]);

	useEffect(() => {
    // Check if there is no ID, then redirect back to the previous component
		// console.log(id)
    if (!id) {
      navigate(-1); // Redirect back to the previous page
      // Or you can redirect to a specific route using navigate('/your-route')
    } else {
			setHospitalId(id);
		}

  }, [id, navigate]);
	
	const handleGoBack = () => {
		navigate(-1);
	}

	return (
		<div className='flex'>			
			<Sidebar />

			<div className='w-10/12 p-5'>
				<div className='flex justify-between items-center'>
					<div className='w-3/12'>
						<p className='text-2xl font-medium'>Hospital Services</p>
					</div>
					
					<div className="w-4/12 flex justify-end">
						<button
							onClick={handleGoBack}
							className='px-4 py-3 text-sm font-medium rounded-md'>
							Back
						</button>
						<Link
							to="/master/hospital-service"
							className='px-4 py-3 text-sm font-medium text-pale-green-500 outline outline-pale-green-500 rounded-md hover:bg-pale-green-500 hover:text-white'
						>
							Service master
						</Link>
					</div>
				</div>

				<HospitalServiceTable
					hospitalId={hospitalId}
				/>
			</div>
		</div>
	);
}

export default HospitalServices;