import React, { useEffect, useState } from 'react';
import {MdOutlineMedicalServices} from 'react-icons/md'; 
import {BiEditAlt, BiTrash} from 'react-icons/bi'; 
import DoctorModal from './DoctorModal';
import RemoveConfirmationModal from '../../components/RemoveConfirmationModal'; // Import the confirmation modal
import authenticatedServiceInstance from '../../services/AuthenticatedService';
import { ToastContainer, toast } from 'react-toastify';
import { fetchDoctors } from './api/fetchDoctors';
import Chamber from '../Chamber/Chamber';
import 'react-toastify/dist/ReactToastify.css';

const DoctorsTable = ({ searchKeywords, isCreateModalOpen, isCreateModalClose }) => {

	const token = authenticatedServiceInstance.getToken(); // Replace with your actual Bearer token
	const [doctors, setDoctors] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [doctorData, setDoctorData] = useState(false);
	const [isCreateMode, setIsCreateMode] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [selectedDoctorToRemove, setSelectedDoctorToRemove] = useState(null);
	const [callAllDoctor, setCallAllDoctor] = useState(true);
	const [selectedDoctor, setSelectedDoctor] = useState(null);
	const [tableHeight, setTableHeight] = useState('34rem');

	const BASE_URL = process.env.REACT_APP_BASE_URL;
	
	const handleResponse = (value) => {
		// console.log(value)
		if (value === 'save') {
			toast.success("Successfully saved");
		} else if (value === 'update') {
			toast.success("Successfully updated");
		} else if (value === 'error') {
			toast.error("Error occured");
		} else {
			toast.error("Something goes wrong!");
		}
	}

	const handleUpdateDoctor = (updatedDoctor) => {

    // Find the index of the updated chamber in the list
    const updatedIndex = doctors.findIndex(doc => doc.id === updatedDoctor.id);
    
    if (updatedIndex !== -1) {
      // Create a new array with the updated chamber
      const updatedDoctorList = [...doctors];
      updatedDoctorList[updatedIndex] = updatedDoctor;
      
      // Update the list of chambers
      setDoctors(updatedDoctorList);
    } else {
			// If the chamber doesn't exist in the list, it's a new chamber
			// Append the new chamber to the list
			setDoctors(prevChamberList => [...prevChamberList, updatedDoctor]);
		}
  };

	useEffect(() => {

		const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
		const screenHeightInPx = window.innerHeight * 0.9;
		const screenHeightInRem = parseInt(screenHeightInPx / rootFontSize) * 0.98;
		setTableHeight(`${screenHeightInRem}rem`);

		const fetchData = async () => {
      try {
        const data = await fetchDoctors(token);
        setDoctors(data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, BASE_URL]);

	// Call searchDoctors function with the searchInput value on input change
	useEffect(() => {
		const searchDoctors = async () => {
		
			try {
				const url = new URL(`${BASE_URL}/doctor/getAllByNameBySpeciality`);
				const params = new URLSearchParams();
				params.append('keywords', searchKeywords);
				params.append('specialist', '');
				params.append('district_id', 0);
				url.search = params.toString();
		
				const response = await fetch(url.toString(), {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				setDoctors(data);
			} catch (error) {
				console.log(error);
			}
		};	

		// if (searchKeywords)
		if (!callAllDoctor)
			searchDoctors();
		
		if (searchKeywords)
			setCallAllDoctor(false);

  }, [searchKeywords, callAllDoctor, token, BASE_URL]);

	// Function to handle opening the modal in create mode
  const handleOpenCreateModal = () => {
    setIsCreateMode(true);
    setDoctorData(false);
    setIsModalOpen(true);
  };

	const handleLinkClick = (data) => {
		// console.log(data)
    setIsModalOpen(true);
		setDoctorData(data);
    setIsCreateMode(false);
  };
	
  const handleCloseModal = () => {
    setIsModalOpen(false);
		setDoctorData(false);
    setIsCreateMode(false);
		isCreateModalClose(false);
  };

	useEffect(() => {
		// console.log(isCreateModalOpen)
		if (isCreateModalOpen) {
			handleOpenCreateModal();
		}
  }, [isCreateModalOpen]);

	const showRemoveConfirmation = (doctor) => {
		setSelectedDoctorToRemove(doctor);
		setShowConfirmation(true);
	};
	
	const hideRemoveConfirmation = () => {
		setSelectedDoctorToRemove(null);
		setShowConfirmation(false);
	};

	const handleRemove = async () => {
		if (!selectedDoctorToRemove) return;

		try {
			const response = await fetch(`${BASE_URL}/doctor/remove/${selectedDoctorToRemove.id}`, {
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.ok) {
				// Remove the chamber from the list
				const updatedDoctorList = doctors.filter(doctor => doctor.id !== selectedDoctorToRemove.id);
				setDoctors(updatedDoctorList);
				hideRemoveConfirmation(); // Close the confirmation modal
			} else {
				alert(response.res);
				console.error('Failed to remove doctor');
			}
		} catch (error) {
			alert(error);
			console.error('Error while removing doctor:', error);
		}
	};

	const handleShowChamber = (doctor) => {
		if (selectedDoctor === doctor.id) {
			if (selectedDoctor) {
				setSelectedDoctor(null);	
			} else {
				setSelectedDoctor(doctor.id);
			}
		} else {
			setSelectedDoctor(doctor.id);
		}
	}

  return (
		<div className={`mt-5 overflow-scroll`} style={{height: tableHeight}}>
			<table className="min-w-full bg-white table-auto border-collapse">
				<thead>
					<tr className='bg-slate-100 sticky top-0'>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500 rounded-tl-xl">Name</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500">Degree</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500">Specialist</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500">Chambers</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500"></th>
					</tr>
				</thead>
				<tbody>
					{ isLoading && Array.from({ length: 5 }, (_, index) => (
						<tr key={index}>
							<td colSpan={5} className='pt-2'>
								<div className="shimmer"></div>
							</td>
						</tr>
					))}
					
					{doctors.map((doctor) => 
						(
						<React.Fragment key={doctor.id}>
						<tr className={selectedDoctor && selectedDoctor === doctor.id ? `border-b bg-slate-100` : `hover:bg-yellow-100`}>
							<td className='py-2 px-4 text-xs'>{doctor.type} {doctor.full_name}</td>
							<td className='py-2 px-4 text-xs'>{doctor.degrees}</td>
							<td className='py-2 px-4 text-xs'>{doctor.specialist}</td>
							<td className='link pl-12'>
								{/* <NavLink to={`/chamber/${doctor.id}/${doctor.type} ${doctor.full_name} - ${doctor.specialist}`}>
									<MdOutlineMedicalServices size={23} />
								</NavLink> */}
								<MdOutlineMedicalServices 
									size={23} 
									onClick={() => handleShowChamber(doctor)}
									/>
							</td>
							<td className='link flex justify-end mr-3 mt-1'>
								<BiEditAlt
									size={23} 
									onClick={() => handleLinkClick(doctor)}  
									/>
								<BiTrash
									size={23}
									className="ml-5 text-red-600 cursor-pointer"
									onClick={() => showRemoveConfirmation(doctor)}
								/>
							</td>
						</tr>
						{selectedDoctor && selectedDoctor === doctor.id && (
						<tr className={selectedDoctor && selectedDoctor === doctor.id ? `shadow-md transition duration-300 relative z-20` : `pt-2`}>
							<td colSpan={5}>
							<Chamber
								id={doctor.id}
								name={doctor.full_name}
								/> 
							</td>
						</tr>
						)}
						</React.Fragment>
					))}
				</tbody>
			</table>
			<DoctorModal
				isOpen={isModalOpen} 
				onClose={handleCloseModal} 
				data={doctorData}
				onUpdate={handleUpdateDoctor}
				isCreateMode={isCreateMode}
				response={handleResponse}
			/>

			<RemoveConfirmationModal
				value="Doctor"
        isOpen={showConfirmation}
        onCancel={hideRemoveConfirmation}
        onConfirm={handleRemove}
      />

			<ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
		</div>
  );
};

export default DoctorsTable;
