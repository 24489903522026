import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import AutocompleteSelect from '../../components/Autocompleteselect';
import { fetchOpdData } from './api/fetchOpdData';
import OpdManagerTable from './layouts/OpdManagerTable';

function Opd() {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const apiEndpoint = `${BASE_URL}doctor/getDoctorAutocomplete`;
	const [dateFrom, setDateFrom] = useState('');
	const [dateTo, setDateTo] = useState('');
	const [dateType, setDateType] = useState('app_date');
	const [mobileNo, setMobileNo] = useState('');
	const [doctorId, setDoctorId] = useState(0);
	const [tableData, setTableData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Function to get the current date in the format "YYYY-MM-DD"
    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    // Set the current date as the default value when the component mounts
    setDateFrom(getCurrentDate());
    setDateTo(getCurrentDate());
	}, []);
	
	const search = async () => {
		setIsLoading(true);
		let opdData = await fetchOpdData(dateType, dateFrom, dateTo, mobileNo, doctorId);
		setIsLoading(false);
		// console.log(opdData)
		setTableData(opdData);
	}
	
	return (
		<div className='flex'>
			<Sidebar />

			<div className='w-10/12 p-5'>
				<div className='flex flex-col'>

					{/* OPD TITLE ON THE LEFT */}
					<div className='w-full mb-3'>
						<p className='text-2xl font-medium'>OPD manager</p>
					</div>

					<div className='flex flex-wrap justify-between items-center'>
						{/* THE OTHER FILTER AND SEARCH BUTTON ON THE RIGHT  */}
						<div className="w-full flex flex-wrap">

							<div className='flex flex-col w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2'>
								<label htmlFor="">Date type</label>
								<select
									className='font-medium h-10 border border-pale-green-300 hover:border-pale-green-500 focus:outline-none focus:border-pale-green-500 rounded p-1'
									value={dateType}
									onChange={(e) => setDateType(e.target.value)}
								>
									<option value="app_date">App date</option>
									<option value="booking_date">Booking date</option>
								</select>
							</div>

							<div className='flex flex-col w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2'>
								<label htmlFor="">Date from</label>
								<input
									type="date"
									value={dateFrom}
									className='font-medium h-10 border border-pale-green-300 hover:border-pale-green-500 focus:outline-none focus:border-pale-green-500 rounded p-1'
									onChange={(e) => setDateFrom(e.target.value)}
								/>
							</div>

							<div className='flex flex-col w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2'>
								<label htmlFor="">Date to</label>
								<input
									type="date"
									value={dateTo}
									className='font-medium h-10 border border-pale-green-300 hover:border-pale-green-500 focus:outline-none focus:border-pale-green-500 rounded p-1'
									onChange={(e) => setDateTo(e.target.value)}
								/>
							</div>

							<div className='flex flex-col w-full sm:w-auto'>
								<label htmlFor="">Patient's mobile no.</label>
								<input
									type='text'
									className='font-medium h-10 border border-pale-green-300 hover:border-pale-green-500 focus:outline-none focus:border-pale-green-500 rounded p-1'
									placeholder='mobile no.'
									value={mobileNo}
									onChange={(e) => setMobileNo(e.target.value)}
								/>
							</div>

						</div>
					</div>


					<div className='flex w-full mt-3'>
						<div className='flex flex-col w-10/12'>
							<label htmlFor="">Doctor</label>
							<AutocompleteSelect
								fetchData={apiEndpoint}
								width=""
								placeholder="Doctors"
								selectValue={doctorId}
								setSelectValue={setDoctorId}
							/>
						</div>


						<div className='flex flex-col w-2/12'>
							<label htmlFor="">&nbsp;</label>
							<button
								className='ml-2 px-5 py-2 text-md font-medium text-white bg-pale-green-500 rounded hover:bg-pale-green-600 focus:outline-none focus:ring focus:ring-pale-green-300'
								onClick={() => {
									search();
								}}
								>
								Search
							</button>
						</div>

					</div>
					<OpdManagerTable
						isLoading={isLoading}
						tableData={tableData}
						search={search}
					/>
				</div> {/* END FLEX COL */}
				
			</div>
		</div>
	);
}

export default Opd;