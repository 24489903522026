import React, {useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticatedService from '../services/AuthenticatedService';

function Login() {

  useEffect(() => {
    document.title = 'Impilo | Login';
  }, []);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const [userIdError, setUserIdError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [authError, setAuthError] = useState("");
  const [loginDisabled, setLoginDisabled] = useState(false);

  const handleChange = (event) => {
    setUserIdError("");
    setPasswordError("");
    setAuthError("");
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(values)
    if (values.username.length === 0) {
      setUserIdError("Username is required")
    } else if (values.password.length === 0) {
      setPasswordError("Password is required")
    } else {
      // console.log(values)
      setLoginDisabled(true)
      // event.target.disabled = true;
      fetch(`${BASE_URL}/authentication/getAuthentication`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: values.username,
          password: values.password,
        }),
      })
      .then((response) => response.json())
      .then((data) => {
        // Handle response data
        if (data.status) {
          // Do something on successful login
          // console.log("Login successful");
          AuthenticatedService.registerSuccessfulLogin(data.token, data.refresh_token);
          navigate('/dashboard');
        } else {
          // Do something on failed login
          // console.log("Login failed");
          setAuthError("Invalid userid or password!")
          setLoginDisabled(false)
          // event.target.disabled = false;
        }
      })
      .catch((error) => {
        // Handle error
        console.error("Error:", error);
        setAuthError("Failed to connect with server! Please check your internet connection.");
        setLoginDisabled(false)
        // event.target.disabled = false;
      });  
    }
  };

  return (
    <div className='flex'>
      <div className="min-h-screen w-full md:w-4/12 lg:w-4/12 flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-12">
        <div className="w-full space-y-8">
          <div>
            <div className='sm:hidden'>
              <img src="./logo.png" alt="logo" className='mx-auto my-auto w-5/12' />
            </div>
            <p className="text-center text-2xl text-gray-900">
              Admin Log in
            </p>
          </div>
          <form className="mt-8 space-y-6" action="#" method="POST">
            
            <div className="rounded-md shadow-sm -space-y-px">
              {userIdError && (
                <div className="mt-1 mb-2 p-2 rounded text-sm bg-red-100 text-red-600">⚠️ {userIdError}</div>
              )}

              {passwordError && (
                <div className="mt-1 mb-2 p-2 rounded text-sm bg-red-100 text-red-600">⚠️ {passwordError}</div>
              )}

              {authError && (
                <div className="mt-1 mb-2 p-2 rounded text-sm bg-red-100 text-red-600">⚠️ {authError}</div>
              )}
              <div>
                <label htmlFor="username" className="sr-only">
                  Username
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                  placeholder="Username"
                  onChange={handleChange}
                />
              </div>
              
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className={`w-full flex justify-center py-3 px-3 border border-transparent text-sm font-medium rounded-md text-white ${
                  loginDisabled
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-current bg-pale-green-300 hover:bg-pale-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                } text-lg`}
                onClick={handleSubmit}
                disabled={loginDisabled}
              > {loginDisabled ? 'Logging in...' : 'Login'}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="min-h-screen md:w-8/12 hidden sm:block items-center justify-center bg-gray-10 px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-center h-full">
          <img src="./logo.png" alt="logo" className='mx-auto my-auto w-7/12' />
        </div>
      </div>
    </div>
  )
}

export default Login;