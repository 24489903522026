import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import SubscriptionTable from '../components/SubscriptionTable';

function Subscriptions() {
	const [searchKeywords, setSearchKeywords] = useState('');

	const handleSearchInputChange = (e) => {
		setSearchKeywords(e.target.value);
	};

	return (
		<div className='flex'>			
			<Sidebar />

			<div className='w-10/12 p-5'>
				<div className='flex justify-between items-center'>
					<div className='w-2/12'>
						<p className='text-2xl font-medium'>Subscriptions</p>
					</div>
					<div className='w-10/12 flex justify-end'>
						<input
							type="text"
							className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-500 focus:w-full"
							placeholder="Search.."
							value={searchKeywords}
							onChange={handleSearchInputChange}
						/>
					</div>
				</div>

				<SubscriptionTable searchKeywords={searchKeywords} />
			</div>
		</div>
	);
}

export default Subscriptions;