import authenticatedServiceInstance from '../../../services/AuthenticatedService';

const updateOpd = async (data, id) => {
  if (!id) return;

	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken(); // Replace with your actual Bearer token

  // console.log(data)
  data['id'] = id;

  try {
    const response = await fetch(`${BASE_URL}opdManager/update`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw new Error('Failed to update');
    }
    return await response.json();
   
  } catch (error) {
    console.error(error);
		throw new Error(error.message);
  }
};

export {updateOpd};
