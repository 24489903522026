import authenticatedServiceInstance from '../../../services/AuthenticatedService';

const fetchHospitalInquiryData = async (date_from, date_to, mobile_no, hospital_id) => {

	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken(); // Replace with your actual Bearer token

  try {
    const response = await fetch(`${BASE_URL}/hospitalInquiry/getInquiryByFilter?date_from=${date_from}&date_to=${date_to}&mobile_no=${mobile_no}&hospital_id=${hospital_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch states');
    }
    return await response.json();
   
  } catch (error) {
    console.error(error);
		throw new Error(error.message);
  }
};

export {fetchHospitalInquiryData};