import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import withNavigation from './services/withNavigation';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import ChangePassword from './pages/ChangePassword';
import Logout from './pages/Logout';
import Doctors from './pages/Doctors/Doctors';
import Hospitals from './pages/Hospitals/Hospitals';
import Chamber from './pages/Chamber/Chamber';
import OpdManager from './pages/OpdManager/OpdManager';
import AbsentManager from './pages/AbsentManager/AbsentManager';
import Subscriptions from './pages/Subscriptions';
import MasterHospitalServices from './pages/MasterHospitalServices/MasterHospitalServices';
import HospitalServices from './pages/HospitalServices/HospitalServices';
import HospitalInquiry from './pages/HospitalInquiry/HospitalInquiry';
import AuthenticatedRoute from './services/AuthenticatedRoute';
import Ambulance from './pages/Ambulance/Ambulance';

function App() {

  const LoginComponentWithNavigation = withNavigation(Login);
  const LogoutComponentWithNavigation = withNavigation(Logout);
  
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<LoginComponentWithNavigation />} />

        <Route path='/dashboard' element={<AuthenticatedRoute/>}>
          <Route path='/dashboard' element={<Dashboard/>}/>
        </Route>

        <Route path='/doctors' element={<AuthenticatedRoute/>}>
          <Route path='/doctors' element={<Doctors/>}/>
        </Route>

        <Route path='/hospitals' element={<AuthenticatedRoute/>}>
          <Route path='/hospitals' element={<Hospitals/>}/>
        </Route>

        <Route path='/ambulance' element={<AuthenticatedRoute/>}>
          <Route path='/ambulance' element={<Ambulance/>}/>
        </Route>

        <Route path='/hospital/services/:id' element={<AuthenticatedRoute/>}>
          <Route path='/hospital/services/:id' element={<HospitalServices/>}/>
        </Route>

        <Route path='/master/hospital-service' element={<AuthenticatedRoute/>}>
          <Route path='/master/hospital-service' element={<MasterHospitalServices />}/>
        </Route>

        <Route path='/chamber/:id/:doctorName' element={<AuthenticatedRoute/>}>
          <Route path='/chamber/:id/:doctorName' element={<Chamber/>}/>
        </Route>

        {/* <Route path='/chamber/scheduler/:chamber_id/:doctor_id/:doctor_name' element={<AuthenticatedRoute/>}>
          <Route path='/chamber/scheduler/:chamber_id/:doctor_id/:doctor_name' element={<ChamberScheduler/>}/>
        </Route> */}

        <Route path='/chamber/absent-manager/:doctorId/:chamberId/:doctorName/:chamberName' element={<AuthenticatedRoute />}>
          <Route path='/chamber/absent-manager/:doctorId/:chamberId/:doctorName/:chamberName' element={<AbsentManager />}/>
        </Route>

        <Route path='/opdManager' element={<OpdManager/>}>
          <Route path='/opdManager' element={<OpdManager/>}/>
        </Route>

        <Route path='/hospitalInquiry' element={<HospitalInquiry/>}>
          <Route path='/hospitalInquiry' element={<HospitalInquiry/>}/>
        </Route>

        <Route path='/users' element={<AuthenticatedRoute/>}>
          <Route path="/users" element={<Users />} />
        </Route>

        <Route path='/subscriptions' element={<AuthenticatedRoute/>}>
          <Route path="/subscriptions" element={<Subscriptions />} />
        </Route>

        <Route path='/changePassword' element={<AuthenticatedRoute/>}>
          <Route path="/changePassword" element={<ChangePassword />} />
        </Route>

        <Route path='/logout' element={<AuthenticatedRoute/>}>
          <Route path="/logout" element={<LogoutComponentWithNavigation />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
