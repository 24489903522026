import React, { useEffect, useState } from 'react';
import {BiEditAlt, BiTrash} from 'react-icons/bi'; 
import AmbulanceModal from './AmbulanceModal';
import RemoveConfirmationModal from '../../../components/RemoveConfirmationModal'; // Import the confirmation modal
import authenticatedServiceInstance from '../../../services/AuthenticatedService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AmbulanceTable = ({ dataList, isCreateModalOpen, isCreateModalClose, isLoading }) => {

	const token = authenticatedServiceInstance.getToken(); // Replace with your actual Bearer token
	const [ambulance, setAmbulance] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [hospitalData, setHospitalData] = useState(false);
	const [isCreateMode, setIsCreateMode] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [selectedToRemove, setSelectedDoctorToRemove] = useState(null);

	const BASE_URL = process.env.REACT_APP_BASE_URL;
	
	useEffect(() => {
		setAmbulance(dataList);
	}, [dataList]);

	const handleResponse = (value) => {
		// console.log(value)
		if (value === 'save') {
			toast.success("Successfully saved");
		} else if (value === 'update') {
			toast.success("Successfully updated");
		} else if (value === 'error') {
			toast.error("Error occured");
		} else {
			toast.error("Something goes wrong!");
		}
	}

	const handleUpdate = (updatedData) => {

    // Find the index of the updated chamber in the list
    const updatedIndex = ambulance.findIndex(doc => doc.id === updatedData.id);
    
    if (updatedIndex !== -1) {
      // Create a new array with the updated chamber
      const updatedList = [...ambulance];
      updatedList[updatedIndex] = updatedData;
      
      // Update the list of chambers
      setAmbulance(updatedList);
    } else {
			// If the chamber doesn't exist in the list, it's a new chamber
			// Append the new chamber to the list
			setAmbulance(prevList => [...prevList, updatedData]);
		}
  };

	// Function to handle opening the modal in create mode
  const handleOpenCreateModal = () => {
    setIsCreateMode(true);
    setHospitalData(false);
    setIsModalOpen(true);
  };

	const handleLinkClick = (data) => {
		// console.log(data)
    setIsModalOpen(true);
		setHospitalData(data);
    setIsCreateMode(false);
  };
	
  const handleCloseModal = () => {
    setIsModalOpen(false);
		setHospitalData(false);
    setIsCreateMode(false);
		isCreateModalClose(false);
  };

	useEffect(() => {
		console.log(isCreateModalOpen)
		if (isCreateModalOpen) {
			handleOpenCreateModal();
		}
  }, [isCreateModalOpen]);

	const showRemoveConfirmation = (hospital) => {
		setSelectedDoctorToRemove(hospital);
		setShowConfirmation(true);
	};
	
	const hideRemoveConfirmation = () => {
		setSelectedDoctorToRemove(null);
		setShowConfirmation(false);
	};

	const handleRemove = async () => {
		if (!selectedToRemove) return;

		try {
			const response = await fetch(`${BASE_URL}/ambulance/remove/${selectedToRemove.id}`, {
				method: 'PUT',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.ok) {
				// Remove the chamber from the list
				const updatedList = ambulance.filter(ambulance => ambulance.id !== selectedToRemove.id);
				setAmbulance(updatedList);
				hideRemoveConfirmation(); // Close the confirmation modal
			} else {
				alert(response.res);
				console.error('Failed to remove ambulance');
			}
		} catch (error) {
			alert(error);
			console.error('Error while removing ambulance:', error);
		}
	};

  return (
		<div className='mt-5 overflow-auto h-[34rem]'>
			<table className="min-w-full bg-white table-auto border-collapse">
				<thead>
					<tr className='bg-slate-100 sticky top-0'>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500 rounded-tl-xl">SL</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500 rounded-tl-xl">Type</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500">Reg. no.</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500">Owner</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500">Owner contact</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500">Ambulance contact</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500"></th>
					</tr>
				</thead>
				<tbody>
					{ isLoading && Array.from({ length: 5 }, (_, index) => (
						<tr key={index}>
							<td colSpan={7} className='pt-2'>
								<div className="shimmer"></div>
							</td>
						</tr>
					))}

					{dataList.length === 0 ? (
						<tr>
							<td colSpan="7" className="text-center py-4 text-xs">
								No data available
							</td>
						</tr>
					) : (
						ambulance.map((data, index) => (
							<tr key={data.id} className='border-b hover:bg-slate-100'>
								<td className='py-2 px-4 text-xs'>{ index + 1 }</td>
								<td className='py-2 px-4 text-xs'>{data.ambulance_type}</td>
								<td className='py-2 px-4 text-xs'>{data.registration_no}</td>
								<td className='py-2 px-4 text-xs'>{data.owner_name}</td>
								<td className='py-2 px-4 text-xs'>{data.owner_contact}</td>
								<td className='py-2 px-4 text-xs'>{data.ambulance_contact}</td>
								
								<td className='link flex justify-end mr-3 mt-1'>
									<BiEditAlt
										size={23} 
										onClick={() => handleLinkClick(data)}  
										/>
									<BiTrash
										size={23}
										className="ml-5 text-red-600 cursor-pointer"
										onClick={() => showRemoveConfirmation(data)}
									/>
								</td>
							</tr>
						))
					)}
				</tbody>
			</table>
			<AmbulanceModal
				isOpen={isModalOpen} 
				onClose={handleCloseModal} 
				data={hospitalData}
				onUpdate={handleUpdate}
				isCreateMode={isCreateMode}
				response={handleResponse}
			/>

			<RemoveConfirmationModal
				value="Ambulance"
        isOpen={showConfirmation}
        onCancel={hideRemoveConfirmation}
        onConfirm={handleRemove}
      />

			<ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
		</div>
  );
};

export default AmbulanceTable;
