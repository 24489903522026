import React from 'react';
import { Navigate, Outlet } from "react-router-dom";
import AuthenticatedService from '../services/AuthenticatedService';

const AuthenticatedRoute = () => {
    if (AuthenticatedService.isUserLoggedIn()) {
        return <Outlet />;
    } else {
        return <Navigate to="/" />
    }
}

export default AuthenticatedRoute