import React, { useEffect, useState } from 'react';
import {BiEditAlt, BiTrash} from 'react-icons/bi'; 
import HospitalModal from './HospitalModal';
import RemoveConfirmationModal from '../../components/RemoveConfirmationModal'; // Import the confirmation modal
import authenticatedServiceInstance from '../../services/AuthenticatedService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const HospitalTable = ({ searchKeywords, isCreateModalOpen, isCreateModalClose }) => {

	const token = authenticatedServiceInstance.getToken(); // Replace with your actual Bearer token
	const [hospitals, setHospitals] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [hospitalData, setHospitalData] = useState(false);
	const [isCreateMode, setIsCreateMode] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [selectedToRemove, setSelectedDoctorToRemove] = useState(null);
	const [callAll, setCallAll] = useState(true);

	const BASE_URL = process.env.REACT_APP_BASE_URL;
	
	const handleResponse = (value) => {
		// console.log(value)
		if (value === 'save') {
			toast.success("Successfully saved");
		} else if (value === 'update') {
			toast.success("Successfully updated");
		} else if (value === 'error') {
			toast.error("Error occured");
		} else {
			toast.error("Something goes wrong!");
		}
	}

	const handleUpdate = (updatedData) => {

    // Find the index of the updated chamber in the list
    const updatedIndex = hospitals.findIndex(doc => doc.id === updatedData.id);
    
    if (updatedIndex !== -1) {
      // Create a new array with the updated chamber
      const updatedList = [...hospitals];
      updatedList[updatedIndex] = updatedData;
      
      // Update the list of chambers
      setHospitals(updatedList);
    } else {
			// If the chamber doesn't exist in the list, it's a new chamber
			// Append the new chamber to the list
			setHospitals(prevList => [...prevList, updatedData]);
		}
  };

	useEffect(() => {
			
		const fetchHospitals = async () => {

			try {
				const response = await fetch(`${BASE_URL}/hospital/getAll`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				setHospitals(data);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
				setIsLoading(false);
			}
		};
		fetchHospitals();
  }, [token, BASE_URL]);

	// Call searchHospital function with the searchInput value on input change
	useEffect(() => {
		const searchHospital = async () => {
		
			try {
				const url = new URL(`${BASE_URL}/hospital/getAllByNameBySpeciality`);
				const params = new URLSearchParams();
				params.append('keywords', searchKeywords);
				params.append('specialist', '');
				params.append('district_id', 0);
				url.search = params.toString();
		
				const response = await fetch(url.toString(), {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				setHospitals(data);
			} catch (error) {
				console.log(error);
			}
		};	

		// if (searchKeywords)
		if (!callAll)
			searchHospital();
		
		if (searchKeywords)
			setCallAll(false);

  }, [searchKeywords, callAll, token, BASE_URL]);

	// Function to handle opening the modal in create mode
  const handleOpenCreateModal = () => {
    setIsCreateMode(true);
    setHospitalData(false);
    setIsModalOpen(true);
  };

	const handleLinkClick = (data) => {
		// console.log(data)
    setIsModalOpen(true);
		setHospitalData(data);
    setIsCreateMode(false);
  };
	
  const handleCloseModal = () => {
    setIsModalOpen(false);
		setHospitalData(false);
    setIsCreateMode(false);
		isCreateModalClose(false);
  };

	useEffect(() => {
		console.log(isCreateModalOpen)
		if (isCreateModalOpen) {
			handleOpenCreateModal();
		}
  }, [isCreateModalOpen]);

	const showRemoveConfirmation = (hospital) => {
		setSelectedDoctorToRemove(hospital);
		setShowConfirmation(true);
	};
	
	const hideRemoveConfirmation = () => {
		setSelectedDoctorToRemove(null);
		setShowConfirmation(false);
	};

	const handleRemove = async () => {
		if (!selectedToRemove) return;

		try {
			const response = await fetch(`${BASE_URL}/hospital/remove/${selectedToRemove.id}`, {
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.ok) {
				// Remove the chamber from the list
				const updatedList = hospitals.filter(hospital => hospital.id !== selectedToRemove.id);
				setHospitals(updatedList);
				hideRemoveConfirmation(); // Close the confirmation modal
			} else {
				alert(response.res);
				console.error('Failed to remove hospital');
			}
		} catch (error) {
			alert(error);
			console.error('Error while removing hospital:', error);
		}
	};

  return (
		<div className='mt-5 overflow-auto h-[34rem]'>
			<table className="min-w-full bg-white table-auto border-collapse">
				<thead>
					<tr className='bg-slate-100 sticky top-0'>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500 rounded-tl-xl">Name</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500">type</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500">address</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500"></th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500"></th>
					</tr>
				</thead>
				<tbody>
					{ isLoading && Array.from({ length: 5 }, (_, index) => (
						<tr key={index}>
							<td colSpan={5} className='pt-2'>
								<div className="shimmer"></div>
							</td>
						</tr>
					))}

					{hospitals.length === 0 ? (
						<tr>
							<td colSpan="5" className="text-center py-4 text-xs">
								No data available
							</td>
						</tr>
					) : (
						hospitals.map((hospital) => (
							<tr key={hospital.id} className='border-b hover:bg-slate-100'>
								<td className='py-2 px-4 text-xs'>{hospital.hospital_name}</td>
								<td className='py-2 px-4 text-xs'>{hospital.hospital_type}</td>
								<td className='py-2 px-4 text-xs'>{hospital.address}</td>
								<td className='py-2 px-4 text-xs'>
									<Link 
										to={`/hospital/services/${hospital.id}`}
										className='text-blue-400 hover:text-blue-500'>
										Services 
									</Link>
								</td>
								<td className='link flex justify-end mr-3 mt-1'>
									<BiEditAlt
										size={23} 
										onClick={() => handleLinkClick(hospital)}  
										/>
									<BiTrash
										size={23}
										className="ml-5 text-red-600 cursor-pointer"
										onClick={() => showRemoveConfirmation(hospital)}
									/>
								</td>
							</tr>
						))
					)}
				</tbody>
			</table>
			<HospitalModal
				isOpen={isModalOpen} 
				onClose={handleCloseModal} 
				data={hospitalData}
				onUpdate={handleUpdate}
				isCreateMode={isCreateMode}
				response={handleResponse}
			/>

			<RemoveConfirmationModal
				value="Hospital"
        isOpen={showConfirmation}
        onCancel={hideRemoveConfirmation}
        onConfirm={handleRemove}
      />

			<ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
		</div>
  );
};

export default HospitalTable;
