import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import MasterHospitalServiceTable from './MasterHospitalServiceTable';
import { useNavigate } from 'react-router-dom';
// import MasterHospitalServiceModal from './MasterHospitalServiceModal';

function MasterHospitalServices() {
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

	useEffect(() => {
		document.title = "Master Hospital Services";
	});

	const handleCreateModal = (value) => {
    setIsCreateModalOpen(value);
  };

	const navigate = useNavigate();
	const handleGoBack = () => {
		navigate(-1);
	}

	return (
		<div className='flex'>			
			<Sidebar />

			<div className='w-10/12 p-5'>
				<div className='flex justify-between items-center'>
					<div className='w-8/12'>
						<p className='text-2xl font-medium'>Master Hospital Services</p>
					</div>
					
					<div className="w-4/12 flex justify-end">
						<button
							onClick={handleGoBack}
							className='px-4 py-3 text-sm font-medium rounded-md'>
							Back
						</button>
						<button
							className='px-4 py-3 text-sm font-medium text-white bg-pale-green-500 rounded-md hover:bg-pale-green-600 focus:outline-none focus:ring focus:ring-pale-green-300'
							onClick={() => {
								setIsCreateModalOpen(true);
							}}
						>
							Add new Service
						</button>
					</div>
				</div>
				<MasterHospitalServiceTable 
					isCreateModalOpen={isCreateModalOpen}
					isCreateModalClose={handleCreateModal}
				/>
			</div>
		</div>
	);
}

export default MasterHospitalServices;