import React, { useState, useEffect, useCallback } from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import authenticatedServiceInstance from '../../../services/AuthenticatedService';

const AmbulanceModal = ({ isOpen, onClose, data, onUpdate, isCreateMode, response }) => {
  // console.log(data)
  const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken();
  const [validationErrors, setValidationErrors] = useState({});
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  const initValues = 
    {
      id: 0,
      ambulance_type: "",
      registration_no: "",
      owner_name: "",
      owner_address: "",
      state_id: 0,
      district_id: 0,
      driver_name: "",
      driver_licence_no: "",
      ambulance_contact: "",
      owner_contact: "",
      pincode: "",
      charges: "",
      is_active: 1,
      ambulance_location: "",
    };
  const [values, setValues] = useState(initValues);

  const handleChange = (event) => {
    const fieldName = event.target.name;

    // Clear the validation error for the changed field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: '',
    }));

    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  }
  const fetchDistricts = useCallback(async (stateId) => {

    // console.log(stateId)
    try {
      const response = await fetch(`${BASE_URL}/location/findDistrictByid/${stateId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch states');
      }
      const res = await response.json();
      setDistricts(res);

      if (isCreateMode) {
        if (res.length > 0 && res[0].id) {
          setValues({
            id: 0,
            ambulance_type: "",
            registration_no: "",
            owner_name: "",
            owner_address: "",
            state_id: stateId,
            district_id: res[0].id, // Default to active for new chambers
            driver_name: "",
            driver_licence_no: "",
            ambulance_contact: "",
            owner_contact: "",
            pincode: "",
            charges: "",
            is_active: 1,
            ambulance_location: "",
          });
        }
      }

    } catch (error) {
      console.error(error);
    }
  }, [isCreateMode, BASE_URL, token]);

  useEffect(() => {
    // console.log(isCreateMode)
    // console.log(data)
    
    if (isCreateMode) {
      // console.log('create mode')
      setValues({
        id: 0,
        ambulance_type: "",
        registration_no: "",
        owner_name: "",
        owner_address: "",
        state_id: 0,
        district_id: 0,
        driver_name: "",
        driver_licence_no: "",
        ambulance_contact: "",
        owner_contact: "",
        pincode: "",
        charges: "",
        is_active: 1,
        ambulance_location: "",
      });
    }

    // Function to fetch data from the API
    const fetchStates = async () => {
      try {
        const response = await fetch(`${BASE_URL}/location/findAllState`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch states');
        }
        const data = await response.json();
        setStates(data);
        if (data.length === 1) {
          // console.log(data[0].id)
          fetchDistricts(data[0].id);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (data) {
      setValues({
        id: data.id,
        ambulance_type: data.ambulance_type,
        registration_no: data.registration_no,
        owner_name: data.owner_name,
        owner_address: data.owner_address,
        state_id: data.state_id,
        district_id: data.district_id,
        driver_name: data.driver_name,
        driver_licence_no: data.driver_licence_no,
        ambulance_contact: data.ambulance_contact,
        owner_contact: data.owner_contact,
        pincode: data.pincode,
        charges: data.charges,
        is_active: data.is_active,
        ambulance_location: data.ambulance_location,
      });
    }

    // Fetch data from the API when the component mounts
    if (isOpen) {
      fetchStates();
    }

  }, [isCreateMode, data, isOpen, BASE_URL, token, fetchDistricts]);

  useEffect(() => {
    // console.log(data)
   
    // Fetch data from the API when the component mounts or when stateId changes
    if (data !== false) {
      fetchDistricts(data.stateId);
    }
    // }
  }, [data, fetchDistricts]);

  
  const submit = (event) => {
    // console.log(values)
    event.preventDefault();

    // Clear any previous validation errors
    setValidationErrors({});

    // Validate each field
    const errors = {};

    if (!values.ambulance_type) {
      errors.ambulance_type = 'Required';
    }
    if (!values.registration_no) {
      errors.registration_no = 'Required';
    }

    if (!values.ambulance_location) {
      errors.ambulance_location = 'Required';
    }

    if (Object.keys(errors).length > 0) {
      // Update the validation errors state
      setValidationErrors(errors);
      return;
    }
    
    let uri = "";
    // console.log(values.id)

    if (values.id === 0) {
      uri = `${BASE_URL}/ambulance/save`;
    } else {
      uri = `${BASE_URL}/ambulance/update`;
    }

    const updatedData = { ...values }; // Create a copy of the current chamber values
    
    fetch(uri, {
      method: values.id === 0 ? 'POST' : 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values)
    }).then((response) => response.json())
    .then((data) => {
      if (data.status === 200) {
        // setMsgSuccess(true);
        if (values.id === 0) {
          response("save");
        } else {
          response("update");
        }
        setValues({
          ...values, 
          id: 0,
          ambulance_type: "",
          registration_no: "",
          owner_name: "",
          owner_address: "",
          state_id: 0,
          district_id: 0,
          driver_name: "",
          driver_licence_no: "",
          ambulance_contact: "",
          owner_contact: "",
          pincode: "",
          charges: "",
          is_active: 1,
          ambulance_location: "",
        })
        onClose();
        onUpdate(updatedData);
      } else {
        // setMsgError(true);
        response("error");
      }
    })
  }
  
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? 'visible' : 'invisible'
      }`}
    >
      {/* Black shadow */}
      <div
        className={`fixed inset-0 bg-black opacity-50 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={onClose}
      ></div>

      <div className="bg-white p-5 rounded shadow-md relative t-0 w-full md:w-1/2 max-h-full overflow-y-auto">
        <div className="flex justify-between">
          <h2 className="text-xl font-bold mb-4">Hospital</h2>
          <span onClick={onClose} className="link pt-0">
            <AiOutlineClose size={23} />
          </span>
        </div>

        <div className="w-full">
          <form action="">

            <div className="flex flex-wrap mb-5">

              <div className='w-full md:w-1/2 px-4 mt-3'>
                <label htmlFor="type">Ambulance type</label>
                <select 
                  name="ambulance_type" 
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.ambulance_type}
                  onChange={handleChange}
                  >
                  <option value="">--</option>
                  <option value="OMNI VAN">OMNI VAN</option>
                  <option value="BOLERO SUV">BOLERO SUV</option>
                  <option value="ICU">ICU</option>
                </select>
                {validationErrors.ambulance_type && <p className="text-red-500">{validationErrors.ambulance_type}</p>}
              </div>

              <div className="w-full md:w-1/2 px-4 mt-3">
                <label htmlFor="">Registration no.</label>
                <input
                  type="text"
                  name="registration_no"
                  value={values.registration_no}
                  className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                  onChange={handleChange}
                />
                {validationErrors.registration_no && <p className="text-red-500">{validationErrors.registration_no}</p>}
              </div>

              <div className="w-full md:w-full px-4 mt-3">
                <label htmlFor="">Owner name</label>
                <input
                  type="text"
                  name="owner_name"
                  value={values.owner_name}
                  className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                  onChange={handleChange}
                />
              </div>

              <div className="w-full md:w-1/2 px-4 mt-3">
                <label htmlFor="">Owner address</label>
                <textarea 
                  rows="4"
                  name="owner_address"
                  value={values.owner_address}
                  className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className='flex flex-col w-full md:w-1/2'>
                
                <div className='w-full mt-3 px-4'>
                  <label htmlFor="">State</label>
                  <select 
                    name="state_id"
                    className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                    value={values.state_id}
                    onChange={handleChange}
                  >
                    {/* Populate the options from the states data */}
                    {states.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.state_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='w-full mt-3 px-4'>
                  <label htmlFor="">District</label>
                  <select 
                    name="district_id"
                    className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                    value={values.district_id}
                    onChange={handleChange}
                  >
                    {/* Populate the options from the districts data */}
                    {districts.map((districts) => (
                      <option key={districts.id} value={districts.id}>
                        {districts.district_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Driver name</label>
                <input 
                  type="text"
                  name="driver_name"
                  value={values.driver_name}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Driver licence no.</label>
                <input 
                  type="text"
                  name="driver_licence_no"
                  value={values.driver_licence_no}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Ambulance contact</label>
                <input 
                  type="text"
                  name="ambulance_contact"
                  value={values.ambulance_contact}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Owner contact</label>
                <input 
                  type="text"
                  name="owner_contact"
                  value={values.owner_contact}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Ambulance operating location</label>
                <input 
                  type="text"
                  name="ambulance_location"
                  value={values.ambulance_location}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Charge</label>
                <input 
                  type="text"
                  name="charges"
                  value={values.charges}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Pincode</label>
                <input 
                  type="text"
                  name="pincode"
                  value={values.pincode}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Active</label>
                <select 
                  name="is_active"
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.is_active}
                  onChange={handleChange}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>

            </div>

          </form>
        </div>

        <div className='flex justify-between'>
          <button className="mt-4 px-4 py-2 text-pale-green-700 rounded" onClick={onClose}>
            Close
          </button>
          <button className="mt-4 px-4 py-2 bg-pale-green-500 text-white rounded"
            onClick={submit}
            >
            Save changes
          </button>
        </div>
      </div>

    </div>
  );
};

export default AmbulanceModal;
