import authenticatedServiceInstance from '../../../services/AuthenticatedService';

const fetchDoctors = async () => {
	
	const token = await authenticatedServiceInstance.getToken(); // Replace with your actual Bearer token
	const BASE_URL = process.env.REACT_APP_BASE_URL;

	try {
		const response = await fetch(`${BASE_URL}doctor/getAllDoctors`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return await response.json();
		// const data = await response.json();
		// setDoctors(data);
		// setIsLoading(false);
	} catch (error) {
		console.log(error);
		throw new Error(error.message);
		// setIsLoading(false);
	}
};

export {fetchDoctors};