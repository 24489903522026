import React, {useState} from 'react';
import {AiFillFileImage} from 'react-icons/ai';
import ImageViewModal from './ImageViewModal';

const HospitalInquiryTable = ({ isLoading, tableData, search }) => {
	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [isImageOpen, setIsImageOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(false);
	const [selectedImageType, setSelectedImageType] = useState(false);

  function isPdfUrl(url) {
    return /\.pdf$/i.test(url);
  }

  const showImageModal = (data, type) => {
    // console.log(data)
    if (isPdfUrl(data)) {
      window.open(BASE_URL + data, '_blank');
      return;
    }
		setSelectedImage(data);
    setSelectedImageType(type);
		setIsImageOpen(true);
  };

  const hideImageModal = () => {
		setSelectedImage(null);
    setSelectedImageType(null);
		setIsImageOpen(false);
	};
  
  return (
    <div className='mt-5 overflow-auto h-[38rem]'>
      
					{ isLoading && Array.from({ length: 5 }, (_, index) => (
              <div className="shimmer" key={index}></div>
          ))}
          {
            tableData.length === 0 ? (
              <div className='text-center p-3'>
                No data available
              </div>
            ) : (
              tableData.map((data) => (
                <React.Fragment key={data.id}>
                  <div className='p-2 shadow-sm rounded-md mb-2 border bg-slate-50' key={data.id}>

                    <div className='flex'>
                      <div className='text-xs mb-3 w-4/12'>Submitted on: {data.created_datetime} { data.created_day }</div>
                      <div className='text-xs mb-3 w-4/12 flex'>
                        {data.prescription_file.length > 0 &&
                          ( 
                            <>
                            Prescription:
                            <AiFillFileImage
                              size={18}
                              onClick={(e) => showImageModal(data.prescription_file, 'prescription')}
                            />
                          </>
                         )
                        }
                      </div>
                      <div className='text-xs mb-3 w-4/12 flex'>
                        {data.report_file.length > 0 &&
                          ( 
                            <>
                            Report:
                            <AiFillFileImage
                              size={18}
                              onClick={(e) => showImageModal(data.report_file, 'report')}
                            />
                          </>
                         )
                        }
                      </div>
                    </div>

                    <div className='flex'>

                      <div className='w-4/12'>
                        <div>
                          Name: { data.member_name}
                        </div>
                        <div>
                          Age: { data.m_age_y}y { data.m_age_m}m { data.m_age_d}d
                        </div>
                        <div>
                          Gender: { data.member_gender}
                        </div>
                        <div>
                          Mobile: { data.submitted_by}
                        </div>
                      </div>

                      <div className='w-4/12'>
                        <div>
                          Need within: { data.need_within_days }
                        </div>
                        <div>
                          Hotel support: { data.is_hotel_support === "1" ? 'Yes' : 'No' }
                        </div>
                        <div>
                          Hotel budget: { data.is_hotel_support === "1" ? data.hotel_budget : 'N/A' }
                        </div>
                        <div>
                          Guide support: { data.is_guide_support === "1" ? 'Yes' : 'No' }
                        </div>
                      </div>

                      <div className='w-4/12'>
                        <div>
                          Travel support: { data.is_travel_support === "1" ? 'Yes' : 'No' }
                        </div>
                        <div>
                          Train support: { data.is_train_support === "1" ? 'Yes' : 'No' }
                        </div>
                        <div>
                          Flight support: { data.is_flight_support === "1" ? 'Yes' : 'No' }
                        </div>
                        <div>
                          Pickup & drop: { data.is_pickup_drop === "1" ? 'Yes' : 'No' }
                        </div>
                      </div>
                    </div>

                    <div className='font-bold mt-2'>Hospitals</div>
                    {data.hospitals.map((hospitalData) => (
                      <div className='border p-2 bg-white' key={hospitalData.hospital_id}>
                        <div>
                          {hospitalData.hospital_name}
                        </div>
                        <div>
                          {hospitalData.address}
                        </div>
                        <div>
                          {hospitalData.district_name}
                        </div>
                        <div>
                          {hospitalData.state_name}
                        </div>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ))
            )
          }
      <ImageViewModal
        isOpen={isImageOpen}
        onClose={hideImageModal}
        data={selectedImage}
        type={selectedImageType}
      />
    </div>
  );
}

export default HospitalInquiryTable;
