import authenticatedServiceInstance from '../../../services/AuthenticatedService';

const findDistrictByid = async(stateId) => {

	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken();

  try {
    const response = await fetch(`${BASE_URL}/location/findDistrictByid/${stateId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch states');
    }
    return await response.json();
  
  } catch (error) {
    console.error(error);
		throw new Error(error.message);
  }
}

const fetchStates = async () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken();

    try {
      const response = await fetch(`${BASE_URL}/location/findAllState`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch states');
      }
      return await response.json();
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }
}

const searchAll = async (stateId, districtId, pincode, locality) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken();

  try {
    const url = new URL(`${BASE_URL}/ambulance/search`);
    const params = new URLSearchParams();
    params.append('state_id', stateId);
    params.append('district_id', districtId);
    params.append('pincode', pincode);
    params.append('locality', locality);

    url.search = params.toString();

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch');
    }

    return await response.json();
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
}

export { findDistrictByid, fetchStates, searchAll };
