import React, { useState } from 'react';
// import { useParams } from 'react-router';
// import { NavLink } from 'react-router-dom';
import { BiTrash } from 'react-icons/bi'; 
import { IoAddOutline } from 'react-icons/io5';
import authenticatedServiceInstance from '../../services/AuthenticatedService';
// import Sidebar from '../../components/Sidebar';
import DaysOfWeek from '../../components/DayOfWeek';
import ChamberSchedulerModal from './ChamberSchedulerModal';
import RemoveConfirmationModal from '../../components/RemoveConfirmationModal'; // Import the confirmation modal
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ChamberScheduler({ doctor_id, chamber_id, day }) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken(); // Replace with your actual Bearer token

	// const { chamber_id, doctor_id, doctor_name } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [selectedDay, setSelectedDay] = useState(null);
	const [schedules, setSchedules] = useState([]);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const [showConfirmation, setShowConfirmation] = useState(false);
	const [selectedScheduleToRemove, setSelectedScheduleToRemove] = useState(null);


  const handleDayClick = async (day) => {
    setSelectedDay(day); // Set the selected day in the main component's state

		if (!day) return;
		setIsLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/doctorSchedule/findScheduleByDoctorByChamberByDay?doctor_id=${doctor_id}&chamber_id=${chamber_id}&day_name=${day}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			const data = await response.json();
			setIsLoading(false);
			setSchedules(data);
		} catch (error) {
			setIsLoading(false);
			alert(error);
			console.error('Error:', error);
		}
  };

	// open modal windows
	const handleOpenCreateModal = () => {
    setIsModalOpen(true);
  };

	const handleCloseModal = () => {
    setIsModalOpen(false);
  };

	const showRemoveConfirmation = (cham) => {
		setSelectedScheduleToRemove(cham);
		setShowConfirmation(true);
	};

	const hideRemoveConfirmation = () => {
		setSelectedScheduleToRemove(null);
		setShowConfirmation(false);
	};

	const handleRemove = async () => {
		if (!selectedScheduleToRemove) return;
		
		try {
			const response = await fetch(`${BASE_URL}/doctorSchedule/remove/${selectedScheduleToRemove.id}`, {
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.ok) {
				// Remove the chamber from the list
				const updatedScheduleList = schedules.filter(cham => cham.id !== selectedScheduleToRemove.id);
				// console.log(updatedScheduleList);
				setSchedules(updatedScheduleList);
				hideRemoveConfirmation(); // Close the confirmation modal
			} else {
				alert(response.res);
				console.error('Failed to remove chamber');
			}
		} catch (error) {
			alert(error);
			console.error('Error while removing chamber:', error);
		}
	};

	const handleResponse = (data) => {
		if (data.id > 0) {
			toast.success("Successfully saved");
			// setSchedules(prevList => [...prevList, data]);
			handleDayClick(selectedDay);
		}
	}

	return (
		<>
			<div className='flex'>			
				<div className='w-10/12 p-5'>
					<div className='flex flex-col justify-between'>
						<div className='flex w-full'>
							<p className='text-2xl font-medium'> Chamber Scheduler</p>
						</div>
					</div>

					<div className='flex flex-col w-full mt-3'>
						<DaysOfWeek 
							onDayClick={handleDayClick}
						/> {/* Pass the callback function to DaysOfWeek component */}
						
						{selectedDay && 
						<div className="flex">
							<div className="w-1/2">
								<div className='px-3'>Selected Day: <strong> {selectedDay} </strong></div>
							</div>

							<div className="w-1/2 text-end px-10">
								<button className='bg-pale-green-500 text-white rounded-full p-2 hover:bg-pale-green-300'
									onClick={handleOpenCreateModal}>
									<IoAddOutline />
								</button>
							</div>
						</div>
						} {/* Display the selected day in the main component */}

						<table className="min-w-full bg-white table-auto border-collapse mt-3">
							<thead>
								<tr className='bg-slate-100 sticky top-0'>
									<th className="py-3 px-4 border-b text-sm font-medium text-slate-500 rounded-tl-xl text-left">Schedule</th>
									<th className="py-3 px-4 border-b text-sm font-medium text-slate-500 rounded-tr-xl text-end"></th>
								</tr>

							</thead>
							<tbody>

								{ isLoading && Array.from({ length: 3 }, (_, index) => (
									<tr key={index}>
										<td colSpan={2} className='pt-1'>
											<div className="shimmer"></div>
										</td>
									</tr>
								))}

								{!isLoading && schedules.length === 0 ?
									<tr>
										<td colSpan={2} className='py-2 px-4 text-xs'>
											No schedule found
										</td>
									</tr>
									: 
									!isLoading && schedules.map((cham) => (
										<tr key={cham.id} className='border-b hover:bg-slate-100'>
											<td className='py-2 px-4 text-xs'>
												{cham.time_from} to {cham.time_to}
											</td>
											<td className='flex justify-end px-12'>
												<BiTrash
													size={23}
													className="ml-5 text-red-600 cursor-pointer"
													onClick={() => showRemoveConfirmation(cham)}
												/>
											</td>
										</tr>
									))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<ChamberSchedulerModal
				isOpen={isModalOpen} 
				onClose={handleCloseModal} 
				doctorId={doctor_id} 
				chamberId={chamber_id} 
				selectedDay={selectedDay}
				response={handleResponse}
			/>

			<RemoveConfirmationModal
				value="Schedule"
        isOpen={showConfirmation}
        onCancel={hideRemoveConfirmation}
        onConfirm={handleRemove}
			/>

			<ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
		</>
	);
}

export default ChamberScheduler;