import React, { useEffect, useState } from 'react';
import {IoMdRemoveCircleOutline, IoIosAddCircleOutline} from 'react-icons/io'; 
import authenticatedServiceInstance from '../../services/AuthenticatedService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HospitalServiceTable = (hospitalId) => {

	const token = authenticatedServiceInstance.getToken(); // Replace with your actual Bearer token
	const [services, setServicess] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const BASE_URL = process.env.REACT_APP_BASE_URL;
	
	const handleResponse = (value) => {
		// console.log(value)
		if (value === 'add') {
			toast.success("Successfully added");
		} else if (value === 'remove') {
			toast.success("Successfully removed");
		} else if (value === 'error') {
			toast.error("Error occured");
		} else {
			toast.error("Something goes wrong!");
		}
	}

	const handleUpdate = (updatedData) => {
		// console.log(updatedData)
    // Find the index of the updated chamber in the list
    const updatedIndex = services.findIndex(service => service.service_id === updatedData[0].service_id);

		// console.log(updatedIndex)
    if (updatedIndex !== -1) {
      // Create a new array with the updated chamber
      const updatedList = [...services];
      updatedList[updatedIndex] = updatedData[0];
      
      // Update the list of chambers
      setServicess(updatedList);
    }
  };

	useEffect(() => {
		
		const fetchServices = async () => {

			try {
				const response = await fetch(`${BASE_URL}/hospitalServices/getServiceByHospitalId?id=${hospitalId.hospitalId}`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				setServicess(data);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
				setIsLoading(false);
			}
		};
		if (hospitalId.hospitalId) {
			fetchServices();
		}
  }, [token, BASE_URL, hospitalId]);

	const removeService = async (id) => {
		if (!id) return;

		try {
			const response = await fetch(`${BASE_URL}/hospitalServices/removeService/${id}`, {
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.ok) {
				// Remove the chamber from the list
				const updatedData = services.filter(services => services.id === id);
				updatedData[0]['id'] = '0';
				handleResponse('remove');
				handleUpdate(updatedData);
			} else {
				alert(response.res);
				console.error('Failed to remove services');
			}
		} catch (error) {
			alert(error);
			console.error('Error while removing services:', error);
		}
	};

	const addService = async (service_id) => {
		if (!service_id) return;

		try {
			await fetch(`${BASE_URL}/hospitalServices/addService`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({"hospital_id": hospitalId.hospitalId, "service_id": service_id})
			}).then((response) => response.json())
			.then((data) => {

				if (data.status === 200) {
					// Remove the chamber from the list
					const updatedData = services.filter(services => services.service_id === service_id);
					updatedData[0]['id'] = data.last_id;
					handleResponse('add');
					handleUpdate(updatedData);
				} else {
					alert(data.res);
					console.error('Failed to add service');
				}
			});
		} catch (error) {
			alert(error);
			console.error('Error while add service:', error);
		}
	}

  return (
		<div className='mt-5 overflow-auto h-[34rem]'>
			<table className="min-w-full bg-white table-auto border-collapse">
				<thead>
					<tr className='bg-slate-100 sticky top-0'>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500 rounded-tl-xl">Service</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500"></th>
					</tr>
				</thead>
				<tbody>
					{ isLoading && Array.from({ length: 5 }, (_, index) => (
						<tr key={index}>
							<td colSpan={2} className='pt-2'>
								<div className="shimmer"></div>
							</td>
						</tr>
					))}

					{services.length === 0 ? (
						<tr>
							<td colSpan="2" className="text-center py-4 text-xs">
								No data available
							</td>
						</tr>
					) : (
						services.map((service) => (
							<tr key={service.service_id} className='border-b hover:bg-slate-100'>
								<td className='py-2 px-4 text-xs'>{service.service_name}</td>
								
								<td className='link flex justify-end mr-3 mt-1'>
									{service.id === "0" ?
									<IoIosAddCircleOutline
										size={23}
										className="ml-5 text-green-700 cursor-pointer"
										onClick={() => addService(service.service_id)}
									/> :
									<IoMdRemoveCircleOutline
										size={23} 
										className="ml-5 text-red-600 cursor-pointer"
										onClick={() => removeService(service.id)}  
										/>
									}
								</td>
							</tr>
						))
					)}
				</tbody>
			</table>
			
			<ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
		</div>
  );
};

export default HospitalServiceTable;
