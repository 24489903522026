import React, {useState} from 'react';

const DaysOfWeek = ({ onDayClick }) => {
  const [selected, setSelected] = useState(null);
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const handleDayClick = (day) => {
    onDayClick(day); // Invoke the callback function with the selected day's name
    setSelected(day);
  };

  return (
    <div style={{ display: 'flex' }}>
      {daysOfWeek.map((day, index) => (
        <div
          key={index}
          className={`
            ${selected === day ? 'bg-pale-green-300 text-white border border-green-500' : ''}
            mb-3 mt-3 mx-1 p-3 border border-gray-400 rounded-md cursor-pointer hover:bg-pale-green-300 hover:text-white`}
          onClick={() => handleDayClick(day)} // Set day name in state onClick
        >
          {day}
        </div>
      ))}
    </div>
  );
};

export default DaysOfWeek;
