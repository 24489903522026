
import React, {useState} from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OpdEditModal from './OpdEditModal';
import OpdCancelModal from './OpdCancelModal';
import OpdCancelViewModal from './OpdCancelViewModal';

const OpdManagerTable = ({isLoading, tableData, search}) => {

	const [isCancelOpen, setIsCancelOpen] = useState(false);
	const [isCancelViewOpen, setIsCancelViewOpen] = useState(false);
	const [isEditOpen, setIsEditOpen] = useState(false);
	const [selectedPatient, setSelectedPatient] = useState(null);


	const showCancelModal = (patient) => {
		setSelectedPatient(patient);
		setIsCancelOpen(true);
	};
	
	const hideCancelModal = () => {
		setSelectedPatient(null);
		setIsCancelOpen(false);
	};

	const showCancelViewModal = (patient) => {
		setSelectedPatient(patient);
		setIsCancelViewOpen(true);
	};
	
	const hideCancelViewModal = () => {
		setSelectedPatient(null);
		setIsCancelViewOpen(false);
	};

	const showEditModal = (patient) => {
		setSelectedPatient(patient);
		setIsEditOpen(true);
	};
	
	const hideEditModal = () => {
		setSelectedPatient(null);
		setIsEditOpen(false);
	};

  return (
		<div className='mt-5 overflow-auto h-[33rem]'>
			<table className="min-w-full bg-white table-auto border-collapse">
				
				<tbody>
					{ isLoading && Array.from({ length: 5 }, (_, index) => (
						<tr key={index}>
							<td colSpan={2} className='pt-2'>
								<div className="shimmer"></div>
							</td>
						</tr>
					))}
					{
						tableData.length === 0 ? (
						<tr>
							<td colSpan="2" className="text-center py-4 text-xs">
								No data available
							</td>
						</tr>
					) : (
						tableData.map((doctorData) => (
							<React.Fragment key={doctorData.doctor_id}>
								<tr className='border-b bg-pale-green-300 text-white font-bold sticky top-0'>
									<td colSpan={9} className='py-2 px-4 text-xs'>{doctorData.doctor_full_name}</td>
									<td className='py-2 px-4 text-xs'>Total: { doctorData.app_booking.length}</td>
								</tr>
								<tr className='bg-slate-100'>
									<td className="py-2 px-4 border-b text-xs font-medium text-slate-500 rounded-tl-xl">Patient name</td>
									<td className="py-2 px-4 border-b text-xs font-medium text-slate-500">age/gender</td>
									<td className="py-2 px-4 border-b text-xs font-medium text-slate-500">contact no.</td>
									<td className="py-2 px-4 border-b text-xs font-medium text-slate-500">app. date</td>
									<td className="py-2 px-4 border-b text-xs font-medium text-slate-500">book. date</td>
									<td className="py-2 px-4 border-b text-xs font-medium text-slate-500">sl</td>
									<td className="py-2 px-4 border-b text-xs font-medium text-slate-500">date of sl</td>
									<td className="py-2 px-4 border-b text-xs font-medium text-slate-500">sl by</td>
									<td className="py-2 px-4 border-b text-xs font-medium text-slate-500"></td>
									<td className="py-2 px-4 border-b text-xs font-medium text-slate-500"></td>
								</tr>
								{doctorData.app_booking.map((appData) => (
									<tr key={appData.id} className={`border-b hover:bg-yellow-100 ${parseInt(appData.is_cancel) === 1 && 'bg-red-100'}`}>
										<td className='py-2 px-4 text-xs'>{appData.m_name}</td>
										<td className='py-2 px-4 text-xs'>{appData.age}/{appData.gender}</td>
										<td className='py-2 px-4 text-xs'>{appData.mobile_no}</td>
										<td className='py-2 px-4 text-xs'>{appData.app_date_formatted}</td>
										<td className='py-2 px-4 text-xs'>{appData.booking_date_formatted}</td>
										<td className='py-2 px-4 text-xs'>{appData.app_sl_no}</td>
										<td className='py-2 px-4 text-xs'>{appData.sl_date_formatted}</td>
										<td className='py-2 px-4 text-xs'>{appData.sl_alloted_by_full_name}</td>
										<td className='link justify-end mr-3 mt-1 text-xs'
											onClick={() => showEditModal(appData)} >	edit</td>
										{parseInt(appData.is_cancel) === 1 ?
											<td className='link justify-end mr-3 mt-1 text-xs text-red-500'
												onClick={() => showCancelViewModal(appData)} >view</td>
											:
											<td className='link justify-end mr-3 mt-1 text-xs text-red-500'
												onClick={() => showCancelModal(appData)} >cancel</td>
										}
									</tr>
								))}
							</React.Fragment>
						))
						
					)}
				</tbody>
			</table>

			<OpdCancelModal
				isOpen={isCancelOpen}
				onClose={hideCancelModal}
				data={selectedPatient}
				onUpdate={search}
			/>

			<OpdCancelViewModal
				isOpen={isCancelViewOpen}
				onClose={hideCancelViewModal}
				data={selectedPatient}
				onUpdate={search}
			/>

			<OpdEditModal
				isOpen={isEditOpen}
				onClose={hideEditModal}
				data={selectedPatient}
				onUpdate={search}
			/>
			
			<ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
		</div>
  );
};

export default OpdManagerTable;
