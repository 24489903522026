import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import authenticatedServiceInstance from '../services/AuthenticatedService';

const AutocompleteSelect = ({ fetchData, width, placeholder, selectValue, setSelectValue }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #ccc',
      borderRadius: '3px',
      boxShadow: 'none',
      width: width || '100%',
    }),
  };

  const handleKeyDown = (e) => {
    // Handle the Backspace key event and clear the value
    if (e.key === 'Backspace' && !e.target.value) {
      setSelectedOption(null);
    }
  };

  const fetchDataFromApi = useCallback(async () => {
    const token = authenticatedServiceInstance.getToken(); // Replace with your actual Bearer token
    try {
      const response = await fetch(fetchData, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setOptions(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [fetchData]);

  useEffect(() => {
    fetchDataFromApi();
  }, [fetchDataFromApi]);


  useEffect(() => {
    // Update the doctorId whenever selectedOption changes
    if (selectedOption) {
      setSelectValue(selectedOption.value);
    } else {
      // Handle the case when no option is selected (clearing the selection)
      setSelectValue('');
    }
  }, [selectedOption, setSelectValue]);
  
  return (
    <div>
      <Select
        options={options}
        isSearchable
        value={selectedOption}
        onChange={setSelectedOption}
        placeholder={placeholder}
        className='font-medium border border-pale-green-300 hover:border-pale-green-500 focus:outline-none focus:border-pale-green-500 rounded-md'
        styles={customStyles}
        onKeyDown={handleKeyDown}
        isClearable="true"
      />
    </div>
  );
};

export default AutocompleteSelect;
