import React, { useEffect, useState } from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import { updateOpd } from '../api/updateOpd';

const OpdEditModal = ({isOpen, onClose, data, onUpdate}) => {
  const [validationErrors, setValidationErrors] = useState({});
  
  const initValues = {
    app_date: "",
    app_sl_no: "",
    mobile_no: ""
  };

  const [values, setValues] = useState(initValues);


  useEffect(() => {
    if (data) {
      setValues({
        app_date: data.app_date,
        app_sl_no: data.app_sl_no,
        mobile_no: data.mobile_no
      });
    }
  }, [data])

  if (!data) return;

  const handleChange = (event) => {
    const fieldName = event.target.name;

    // Clear the validation error for the changed field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: '',
    }));

    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  }

  const submit = async (e) => {
    e.preventDefault();

    // Clear any previous validation errors
    setValidationErrors({});

    // Validate each field
    const errors = {};

    if (!values.app_date) {
      errors.app_date = 'App date is required';
    }
    if (!values.app_sl_no) {
      errors.app_sl_no = 'App sl is required';
    }
    if (!values.mobile_no) {
      errors.mobile_no = 'Mobile no. is required';
    }

    if (Object.keys(errors).length > 0) {
      // Update the validation errors state
      setValidationErrors(errors);
      return;
    }
    
    // console.log(values)
    const res = await updateOpd(values, data.id);
    // console.log(res)
    if (res.status === 200) {
      onUpdate();
    }
  }

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? 'visible' : 'invisible'
      }`}
    >
      {/* Black shadow */}
      <div
        className={`fixed inset-0 bg-black opacity-50 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={onClose}
      ></div>

      <div className="bg-white p-5 rounded shadow-md relative t-0 w-full md:w-1/2 max-h-full overflow-y-auto">
        <div className="flex justify-between">
          <h2 className="text-xl font-bold mb-4">View cancel app of { data.m_name }</h2>
          <span onClick={onClose} className="link pt-0">
            <AiOutlineClose size={23} />
          </span>
        </div>

        <div className="w-full">
          <form action="">

            <div className="flex flex-wrap mb-5">
              <label htmlFor="">App date</label>
              <input
                type="date"
                name="app_date"
                value={values.app_date}
                className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                onChange={handleChange}
              />
              {validationErrors.app_date &&
                <p className="text-red-500">{validationErrors.app_date}</p>
              }
            </div>

            <div className="flex flex-wrap mb-5">
              <label htmlFor="">App sl no.</label>
              <input
                type="number"
                name="app_sl_no"
                value={values.app_sl_no}
                className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                onChange={handleChange}
              />
              {validationErrors.app_sl_no &&
                <p className="text-red-500">{validationErrors.app_sl_no}</p>
              }
            </div>

            <div className="flex flex-wrap mb-5">
              <label htmlFor="">Contact no.</label>
              <input
                type="number"
                name="mobile_no"
                value={values.mobile_no}
                className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                onChange={handleChange}
              />
              {validationErrors.mobile_no &&
                <p className="text-red-500">{validationErrors.mobile_no}</p>
              }
            </div>

            <div className='flex justify-between'>
              <button className="mt-4 px-4 py-2 text-pale-green-700 rounded"
                onClick={onClose}
                tabIndex={-1}
              >
                Close
              </button>
              <button className='mt-4 px-4 py-2 rounded focus:bg-pale-green-300 bg-pale-green-500 text-white'
                onClick={submit}
                >
                Change
              </button>
            </div>
            
          </form>
        </div>
      </div>

    </div>
  );
};

export default OpdEditModal;
