import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AuthenticatedService from './services/AuthenticatedService';
import App from './App';
import reportWebVitals from './reportWebVitals';

const handleUserInteraction = () => {
  if (AuthenticatedService.isUserLoggedIn()) {
    if (AuthenticatedService.hasTokenExpired()) {
        AuthenticatedService.removeTokens();
        window.location.href = '/'; // Redirect to the login page
    }
  }
};

// Listen for user interactions
document.addEventListener('click', handleUserInteraction);
document.addEventListener('keydown', handleUserInteraction);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
