import React, { useState, useEffect, useCallback } from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import authenticatedServiceInstance from '../../services/AuthenticatedService';

const HospitalModal = ({ isOpen, onClose, data, onUpdate, isCreateMode, response }) => {
  // console.log(data)
  const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken();
  const [validationErrors, setValidationErrors] = useState({});
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  const initValues = 
    {
      id: 0,
      hospital_type: "",
      hospital_name: "",
      state_id: 0,
      district_id: 0, // Default to active for new chambers
      pincode: "",
      address: "",
      lat: "",
      long: "",
      contact_1: "",
      contact_2: "",
      email_id: "",
      is_active: 1,
    };
  const [values, setValues] = useState(initValues);

  const handleChange = (event) => {
    const fieldName = event.target.name;

    // Clear the validation error for the changed field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: '',
    }));

    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  }
  const fetchDistricts = useCallback(async (stateId) => {

    // console.log(stateId)
    try {
      const response = await fetch(`${BASE_URL}/location/findDistrictByid/${stateId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch states');
      }
      const res = await response.json();
      setDistricts(res);

      if (isCreateMode) {
        if (res.length > 0 && res[0].id) {
          setValues({ // Make sure to keep the other values unchanged
            id: 0,
            hospital_type: "",
            hospital_name: "",
            state_id: stateId,
            district_id: res[0].id, // Default to active for new chambers
            pincode: "",
            address: "",
            lat: "",
            long: "",
            contact_1: "",
            contact_2: "",
            email_id: "",
            is_active: 1,
          });
        }
      }

    } catch (error) {
      console.error(error);
    }
  }, [isCreateMode, BASE_URL, token]);

  useEffect(() => {
    // console.log(isCreateMode)
    // console.log(data)
    
    if (isCreateMode) {
      // console.log('create mode')
      setValues({
        id: 0,
        hospital_type: "",
        hospital_name: "",
        state_id: 0,
        district_id: 0, // Default to active for new chambers
        pincode: "",
        address: "",
        lat: "",
        long: "",
        contact_1: "",
        contact_2: "",
        email_id: "",
        is_active: 1,
      });
    }

    // Function to fetch data from the API
    const fetchStates = async () => {
      try {
        const response = await fetch(`${BASE_URL}/location/findAllState`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch states');
        }
        const data = await response.json();
        setStates(data);
        if (data.length === 1) {
          // console.log(data[0].id)
          fetchDistricts(data[0].id);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (data) {
      setValues({
        id: data.id,
        hospital_type: data.hospital_type,
        hospital_name: data.hospital_name,
        state_id: data.state_id,
        district_id: data.district_id,
        pincode: data.pincode,
        address: data.address,
        lat: data.lat,
        long: data.long,
        contact_1: data.contact_1,
        contact_2: data.contact_2,
        email_id: data.email_id,
        is_active: data.is_active,
      });
    }

    // Fetch data from the API when the component mounts
    if (isOpen) {
      fetchStates();
    }

  }, [isCreateMode, data, isOpen, BASE_URL, token, fetchDistricts]);

  useEffect(() => {
    // console.log(data)
   
    // Fetch data from the API when the component mounts or when stateId changes
    if (data !== false) {
      fetchDistricts(data.stateId);
    }
    // }
  }, [data, fetchDistricts]);

  
  const submit = (event) => {
    // console.log(values)
    event.preventDefault();

    // Clear any previous validation errors
    setValidationErrors({});

    // Validate each field
    const errors = {};

    if (!values.hospital_type) {
      errors.hospital_type = 'Type is required';
    }
    if (!values.hospital_name) {
      errors.hospital_name = 'Name is required';
    }
    if (!values.address) {
      errors.address = 'Address is required';
    }
    if (!values.contact_1) {
      errors.contact_1 = 'Contact #1 is required';
    }

    if (Object.keys(errors).length > 0) {
      // Update the validation errors state
      setValidationErrors(errors);
      return;
    }
    
    let uri = "";
    // console.log(values.id)

    if (values.id === 0) {
      uri = `${BASE_URL}/hospital/save`;
    } else {
      uri = `${BASE_URL}/hospital/update`;
    }

    const updatedData = { ...values }; // Create a copy of the current chamber values
    
    fetch(uri, {
      method: values.id === 0 ? 'POST' : 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values)
    }).then((response) => response.json())
    .then((data) => {
      if (data.status === 200) {
        // setMsgSuccess(true);
        if (values.id === 0) {
          response("save");
        } else {
          response("update");
        }
        setValues({
          ...values, 
          id: 0,
          hospital_type: "",
          hospital_name: "",
          state_id: 0,
          district_id: 0, // Default to active for new chambers
          pincode: "",
          address: "",
          lat: "",
          long: "",
          contact_1: "",
          contact_2: "",
          email_id: "",
          is_active: 1,
        })
        onClose();
        onUpdate(updatedData);
      } else {
        // setMsgError(true);
        response("error");
      }
    })
  }
  
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? 'visible' : 'invisible'
      }`}
    >
      {/* Black shadow */}
      <div
        className={`fixed inset-0 bg-black opacity-50 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={onClose}
      ></div>

      <div className="bg-white p-5 rounded shadow-md relative t-0 w-full md:w-1/2 max-h-full overflow-y-auto">
        <div className="flex justify-between">
          <h2 className="text-xl font-bold mb-4">Hospital</h2>
          <span onClick={onClose} className="link pt-0">
            <AiOutlineClose size={23} />
          </span>
        </div>

        <div className="w-full">
          <form action="">

            <div className="flex flex-wrap mb-5">

              <div className='w-full px-4 mt-3'>
                <label htmlFor="type">Type</label>
                <select 
                  name="hospital_type" 
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.hospital_type}
                  onChange={handleChange}
                  >
                  <option value="">--</option>
                  <option value="Corporate Hospital">Corporate Hospital</option>
                  <option value="Private Hospital">Private Hospital</option>
                  <option value="Govt. Hospital">Govt. Hospital</option>
                  <option value="Trust Hospital">Trust Hospital</option>
                  <option value="Nursing Home">Nursing Home</option>
                </select>
                {validationErrors.hospital_type && <p className="text-red-500">{validationErrors.hospital_type}</p>}
              </div>

              <div className="w-full px-4 mt-3">
                <label htmlFor="">Hospital name</label>
                <input
                  type="text"
                  name="hospital_name"
                  value={values.hospital_name}
                  className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                  onChange={handleChange}
                />
                {validationErrors.hospital_name && <p className="text-red-500">{validationErrors.hospital_name}</p>}
              </div>

              <div className="w-full px-4 mt-3">
                <label htmlFor="">Address</label>
                <textarea 
                  rows="3"
                  name="address"
                  value={values.address}
                  className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                  onChange={handleChange}
                ></textarea>
              </div>

              
              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">State</label>
                <select 
                  name="state_id"
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.state_id}
                  onChange={handleChange}
                >
                  {/* Populate the options from the states data */}
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.state_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">District</label>
                <select 
                  name="district_id"
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.district_id}
                  onChange={handleChange}
                >
                  {/* Populate the options from the districts data */}
                  {districts.map((districts) => (
                    <option key={districts.id} value={districts.id}>
                      {districts.district_name}
                    </option>
                  ))}
                </select>
              </div>

              
              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Lat.</label>
                <input 
                  type="text"
                  name="lat"
                  value={values.lat}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Long.</label>
                <input 
                  type="text"
                  name="long"
                  value={values.long}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>


              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Contact #1</label>
                <input 
                  type="text"
                  name="contact_1"
                  value={values.contact_1}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
                {validationErrors.contact_1 && <p className="text-red-500">{validationErrors.contact_1}</p>}
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Contact #2</label>
                <input 
                  type="text"
                  name="contact_2"
                  value={values.contact_2}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>

              <div className='w-full mt-3 px-4'>
                <label htmlFor="">Email id</label>
                <input 
                  type="text"
                  name="email_id"
                  value={values.email_id}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Pin</label>
                <input 
                  type="text"
                  name="pincode"
                  value={values.pincode}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Active</label>
                <select 
                  name="is_active"
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.is_active}
                  onChange={handleChange}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>

            </div>

          </form>
        </div>

        <div className='flex justify-between'>
          <button className="mt-4 px-4 py-2 text-pale-green-700 rounded" onClick={onClose}>
            Close
          </button>
          <button className="mt-4 px-4 py-2 bg-pale-green-500 text-white rounded"
            onClick={submit}
            >
            Save changes
          </button>
        </div>
      </div>

    </div>
  );
};

export default HospitalModal;
