import React, { useEffect, useState, useCallback } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import authenticatedServiceInstance from '../services/AuthenticatedService';

const SubscriptionTable = ({ searchKeywords }) => {
	const token = authenticatedServiceInstance.getToken(); // Replace with your actual Bearer token
	const [subscriptions, setSubscriptions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [showConfirmation, setShowConfirmation] = useState(false); // New state for confirmation dialog
	const [subscriptionId, setSubscriptionId] = useState(0); // New state for confirmation dialog
	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const fetchSubscriptions = useCallback(async () => {

		try {
			const response = await fetch(`${BASE_URL}/subscription/getAllSubscriptions`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setSubscriptions(data);
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
	}, [token, BASE_URL]);

	const searchSubscription = useCallback(async () => {

		try {
			const url = new URL(`${BASE_URL}/subscription/getSubscriptionsByOrderIdByNameByMobile`);
			const params = new URLSearchParams();
			params.append('keyword', searchKeywords);
			url.search = params.toString();

			const response = await fetch(url.toString(), {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setSubscriptions(data);
		} catch (error) {
			console.log(error);
		}
	}, [searchKeywords, token, BASE_URL]);


	useEffect(() => {
		const fetchData = async () => {
		  if (searchKeywords) {
			searchSubscription();
		  } else {
			fetchSubscriptions();
		  }
		};
	  
		fetchData();
	}, [searchKeywords, fetchSubscriptions, searchSubscription]);

	// Function to handle subscription removal
	const handleRemoveSubscription = async () => {
		setShowConfirmation(false); // Close the confirmation dialog

		if (setSubscriptionId === 0) {
			return false;
		}
		// Perform the actual removal logic here
		// ...
		// const BASE_URL = process.env.REACT_APP_BASE_URL;

		try {
			fetch(`${BASE_URL}/subscription/removeById`, {
				method: "POST",
				headers: {
				  "Content-Type": "application/json",
				},
				body: JSON.stringify({
				  id: subscriptionId,
				}),
			  })
			  .then((response) => response.json())
			  .then((data) => {
				// Handle response data
				// console.log(data)
				setSubscriptions(data);
			});

		} catch (error) {
			console.log(error);
		}
	};

	// Function to show the confirmation dialog
	const showRemoveConfirmation = (id) => {
		setShowConfirmation(true);
		setSubscriptionId(id);
	};

	return (
		<div className="mt-5 overflow-auto h-[34rem]">
			{showConfirmation && ( // Render the confirmation dialog conditionally
				<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
					<div className="bg-white p-8 rounded">
						<p>Are you sure you want to remove this subscription?</p>
						<div className="flex justify-end mt-4">
							<button
								className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
								onClick={handleRemoveSubscription}
							>
								Remove
							</button>
							<button
								className="px-4 py-2 ml-4 text-gray-700 bg-gray-100 rounded hover:bg-gray-200 border"
								onClick={() => setShowConfirmation(false)}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			)}
			<table className="min-w-full bg-white table-auto border-collapse">
				<thead>
					<tr className="bg-slate-100 sticky top-0">
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500 rounded-tl-xl">
							Date
						</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500">Order id</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500">Customer</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500">Billing name</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500">Amount</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500">
							Subscription upto
						</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500">Status</th>
						<th className="py-3 px-4 border-b text-sm font-medium text-slate-500"></th>
					</tr>
				</thead>
				<tbody>
					{isLoading &&
						Array.from({ length: 5 }, (_, index) => (
							<tr key={index}>
								<td colSpan={5} className="pt-2">
									<div className="shimmer"></div>
								</td>
							</tr>
						))}
					{subscriptions.map((subscription) => (
						<tr key={subscription.id} className={`border-b hover:bg-slate-100 ${subscription.is_delete === "1" ? 'bg-pink-100' : ''}`}>
							<td className="py-2 px-4 text-xs">{subscription.created_datetime_formatted}</td>
							<td className="py-2 px-4 text-xs">{subscription.order_id}</td>
							<td className="py-2 px-4 text-xs">{subscription.customer_id}</td>
							<td className="py-2 px-4 text-xs">{subscription.billing_name}</td>
							<td className="py-2 px-4 text-xs">{subscription.amount}</td>
							<td className="py-2 px-4 text-xs">{subscription.subscription_date_upto}</td>
							<td className="py-2 px-4 text-xs">{subscription.order_status}</td>
							<td className="link pl-5">
								
							{subscription.is_delete === "0" && (
								<AiOutlineDelete 
									size={23} 
									onClick={() => showRemoveConfirmation(subscription.id)} 
								/>
							)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default SubscriptionTable;
