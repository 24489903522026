// import React from 'react';
import { useNavigate } from "react-router-dom";
import AuthenticatedService from '../services/AuthenticatedService';
import { useEffect } from "react";

const Logout = () => {
    const navigate = useNavigate();
    if (AuthenticatedService.isUserLoggedIn()) {
        AuthenticatedService.removeTokens()
    }

    useEffect(() => {
        navigate('/');
    }, [navigate]);
    // return null;
}

export default Logout;