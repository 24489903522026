import React, { useState, useEffect } from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import authenticatedServiceInstance from '../../services/AuthenticatedService';

const MasterHospitalServiceModal = ({ isOpen, onClose, data, onUpdate, isCreateMode, response }) => {
  // console.log(data)
  const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken();
  const [validationErrors, setValidationErrors] = useState({});

  const initValues = 
    {
      id: 0,
      service_name: "",
      is_active: 1,
    };
  const [values, setValues] = useState(initValues);

  const handleChange = (event) => {
    const fieldName = event.target.name;

    // Clear the validation error for the changed field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: '',
    }));

    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  }
  
  useEffect(() => {
    // console.log(isCreateMode)
    // console.log(data)
    
    if (isCreateMode) {
      // console.log('create mode')
      setValues({
        id: 0,
        service_name: "",
        is_active: 1,
      });
    }

    if (data) {
      setValues({
        id: data.id,
        service_name: data.service_name,
        is_active: data.is_active,
      });
    }
  }, [isCreateMode, data]);
  
  const submit = (event) => {
    // console.log(values)
    event.preventDefault();

    // Clear any previous validation errors
    setValidationErrors({});

    // Validate each field
    const errors = {};

		if (!values.service_name) {
      errors.service_name = 'Service name is required';
    }
    
    if (Object.keys(errors).length > 0) {
      // Update the validation errors state
      setValidationErrors(errors);
      return;
    }
    
    let uri = "";
    // console.log(values.id)

    if (values.id === 0) {
      uri = `${BASE_URL}/hospitalServices/save`;
    } else {
      uri = `${BASE_URL}/hospitalServices/update`;
    }

    const updatedData = { ...values };
    
    fetch(uri, {
      method: values.id === 0 ? 'POST' : 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values)
    }).then((response) => response.json())
    .then((data) => {
      if (data.status === 200) {
        // setMsgSuccess(true);
        if (values.id === 0) {
          response("save");
        } else {
          response("update");
        }
        setValues({
          ...values, 
          id: 0,
          service_name: "",
          is_active: 1,
        })
        onClose();
				if (values.id === 0)
					updatedData.id = data.last_id;
        onUpdate(updatedData);
      } else {
        // setMsgError(true);
        response("error");
      }
    })
  }

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? 'visible' : 'invisible'
      }`}
    >
      {/* Black shadow */}
      <div
        className={`fixed inset-0 bg-black opacity-50 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={onClose}
      ></div>

      <div className="bg-white p-5 rounded shadow-md relative t-0 w-full md:w-1/2 max-h-full overflow-y-auto">
        <div className="flex justify-between">
          <h2 className="text-xl font-bold mb-4">Master Hospital Services</h2>
          <span onClick={onClose} className="link pt-0">
            <AiOutlineClose size={23} />
          </span>
        </div>

        <div className="w-full">
          <form action="">

            <div className="flex flex-wrap mb-5">

              <div className="w-full px-4 mt-3">
                <label htmlFor="">Service name</label>
                <input
                  type="text"
                  name="service_name"
                  value={values.service_name}
                  className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                  onChange={handleChange}
                />
                {validationErrors.service_name && <p className="text-red-500">{validationErrors.service_name}</p>}
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Active</label>
                <select 
                  name="is_active"
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.is_active}
                  onChange={handleChange}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>

            </div>

          </form>
        </div>

        <div className='flex justify-between'>
          <button className="mt-4 px-4 py-2 text-pale-green-700 rounded" onClick={onClose}>
            Close
          </button>
          <button className="mt-4 px-4 py-2 bg-pale-green-500 text-white rounded"
            onClick={submit}
            >
            Save changes
          </button>
        </div>
      </div>

    </div>
  );
};

export default MasterHospitalServiceModal;
