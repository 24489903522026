import React from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import TimeForm from './TimeForm';

const ChamberSchedulerModal = ({ isOpen, onClose, doctorId, chamberId, selectedDay, response }) => {
  
  const onCreate = (data) => {
    // console.log(data);
    response(data);
  }

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? 'visible' : 'invisible'
      }`}
    >
      {/* Black shadow */}
      <div
        className={`fixed inset-0 bg-black opacity-50 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={onClose}
      ></div>

      <div className="bg-white p-5 rounded shadow-md relative t-0 w-full md:w-1/2">
        <div className="flex justify-between">
          <h2 className="text-xl font-bold mb-4">Chamber scheduler</h2>
          <span onClick={onClose} className="link pt-0">
            <AiOutlineClose size={23} />
          </span>
        </div>

        <div className="w-full overflow-auto max-h-96 p-5">
          <TimeForm
            doctorId={doctorId}
            chamberId={chamberId}
            selectedDay={selectedDay}
            onCreate={onCreate}
          />
        </div>
      </div>
    </div>
  );
};

export default ChamberSchedulerModal;
