import React, { useState, useEffect } from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import authenticatedServiceInstance from '../../services/AuthenticatedService';

const DoctorModal = ({ isOpen, onClose, data, onUpdate, isCreateMode, response }) => {
  // console.log(data)
  const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken();
  const [specialists, setSpecialists] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const initValues = 
    {
      id: 0,
      type: "",
      contact1: "",
      contact2: "",
      degrees: "",
      full_name: "",
      is_active: 1, // Default to active for new chambers
      specialist: "",
      whatsapp: "",
    };
  const [values, setValues] = useState(initValues);

  const handleChange = (event) => {
    const fieldName = event.target.name;

    // Clear the validation error for the changed field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: '',
    }));

    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  }

  useEffect(() => {
    // console.log(isCreateMode)
    // console.log(data)
    
    if (isCreateMode) {
      // console.log('create mode')
      setValues({
        id: 0,
        type: "",
        contact1: "",
        contact2: "",
        degrees: "",
        full_name: "",
        is_active: 1, // Default to active for new chambers
        specialist: "",
        whatsapp: "",
      });
    }

    // Function to fetch data from the API
    const fetchSpecialist = async () => {
      try {
        const response = await fetch(`${BASE_URL}/specialist/findAll`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch states');
        }
        const res = await response.json();
        setSpecialists(res);
      } catch (error) {
        console.error(error);
      }
    };

    if (data) {
      setValues({
        id: data.id,
        type: data.type,
        contact1: data.contact1,
        contact2: data.contact2,
        degrees: data.degrees,
        full_name: data.full_name,
        is_active: data.is_active, // Default to active for new chambers
        specialist: data.specialist,
        whatsapp: data.whatsapp,
      });
    }

    // Fetch data from the API when the component mounts
    if (isOpen) {
      fetchSpecialist();
    }
  }, [isCreateMode, data, isOpen, BASE_URL, token]);
  
  const submit = (event) => {
    // console.log(values)
    event.preventDefault();

    // Clear any previous validation errors
    setValidationErrors({});

    // Validate each field
    const errors = {};

    if (!values.type) {
      errors.type = 'Type is required';
    }
    if (!values.full_name) {
      errors.full_name = 'Full name is required';
    }
    if (!values.specialist) {
      errors.specialist = 'Specialist is required';
    }
    if (!values.contact1) {
      errors.contact1 = 'Contact #1 is required';
    }

    if (Object.keys(errors).length > 0) {
      // Update the validation errors state
      setValidationErrors(errors);
      return;
    }
    
    let uri = "";
    // console.log(values.id)
    if (values.id === 0) {
      uri = `${BASE_URL}/doctor/save`;
    } else {
      uri = `${BASE_URL}/doctor/update`;
    }

    const updatedDoctor = { ...values }; // Create a copy of the current chamber values
    
    fetch(uri, {
      method: values.id === 0 ? 'POST' : 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values)
    }).then((response) => response.json())
    .then((data) => {
      if (data.status) {
        // setMsgSuccess(true);
        if (values.id === 0) {
          response("save");
        } else {
          response("update");
        }
        setValues({
          ...values, 
          id: 0,
          type: "",
          contact1: "",
          contact2: "",
          degrees: "",
          full_name: "",
          is_active: 1, // Default to active for new chambers
          specialist: "",
          whatsapp: "",
        })
        onClose();
        onUpdate(updatedDoctor);
      } else {
        // setMsgError(true);
        response("error");
      }
    })
  }
  
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? 'visible' : 'invisible'
      }`}
    >
      {/* Black shadow */}
      <div
        className={`fixed inset-0 bg-black opacity-50 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={onClose}
      ></div>

      <div className="bg-white p-5 rounded shadow-md relative t-0 w-full md:w-1/2 max-h-full overflow-y-auto">
        <div className="flex justify-between">
          <h2 className="text-xl font-bold mb-4">Doctor</h2>
          <span onClick={onClose} className="link pt-0">
            <AiOutlineClose size={23} />
          </span>
        </div>

        <div className="w-full">
          <form action="">

            <div className="flex flex-wrap mb-5">

              <div className='w-3/12 px-4 mt-3'>
                <label htmlFor="type">Type</label>
                <select 
                  name="type" 
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.type}
                  onChange={handleChange}
                  >
                  <option value="">--</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Dentist">Dentist</option>
                </select>
                {validationErrors.type && <p className="text-red-500">{validationErrors.type}</p>}
              </div>

              <div className="w-9/12 px-4 mt-3">
                <label htmlFor="">Doctor full name</label>
                <input
                  type="text"
                  name="full_name"
                  value={values.full_name}
                  className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                  onChange={handleChange}
                />
                {validationErrors.full_name && <p className="text-red-500">{validationErrors.full_name}</p>}
              </div>

              <div className="w-full md:w-1/2 px-4 mt-3">
                <label htmlFor="">Degree's</label>
                <input
                  type="text"
                  name="degrees"
                  value={values.degrees}
                  className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                  onChange={handleChange}
                />
              </div>

              <div className="w-full md:w-1/2 px-4 mt-3">
                <label htmlFor="">Specialist</label>
                <select 
                  name="specialist"
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.specialist}
                  onChange={handleChange}
                >
                  <option value="">--</option>
                  {/* Populate the options from the states data */}
                  {specialists.map((specialist) => (
                    <option key={specialist.specialist_title} value={specialist.specialist_title}>
                      {specialist.specialist_title}
                    </option>
                  ))}
                </select>
                {validationErrors.specialist && <p className="text-red-500">{validationErrors.specialist}</p>}
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Contact #1</label>
                <input 
                  type="text"
                  name="contact1"
                  value={values.contact1}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
                {validationErrors.contact1 && <p className="text-red-500">{validationErrors.contact1}</p>}
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Contact #2</label>
                <input 
                  type="text"
                  name="contact2"
                  value={values.contact2}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Whatsapp</label>
                <input 
                  type="text"
                  name="whatsapp"
                  value={values.whatsapp}
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  onChange={handleChange}
                />
              </div>

              <div className='w-full md:w-1/2 mt-3 px-4'>
                <label htmlFor="">Active</label>
                <select 
                  name="is_active"
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={values.is_active}
                  onChange={handleChange}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>

            </div>

          </form>
        </div>

        <div className='flex justify-between'>
          <button className="mt-4 px-4 py-2 text-pale-green-700 rounded" onClick={onClose}>
            Close
          </button>
          <button className="mt-4 px-4 py-2 bg-pale-green-500 text-white rounded"
            onClick={submit}
            >
            Save changes
          </button>
        </div>
      </div>

    </div>
  );
};

export default DoctorModal;
