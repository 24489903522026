import React, { useState } from 'react';
import authenticatedServiceInstance from '../../services/AuthenticatedService';

const TimeInput = ({ label, value, onChange }) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-600 mb-1">{label}</label>
      <input
        type="time"
        className="mr-2 p-2 border rounded"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

const TimeForm = ({ doctorId, chamberId, selectedDay, onCreate }) => {

  const BASE_URL = process.env.REACT_APP_BASE_URL;
	const token = authenticatedServiceInstance.getToken();
  
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
    setErrorMsg('');
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
    setErrorMsg('');
  };

  const handleAddButtonClick = () => {
    // Handle adding the time range logic here
    if (startTime.length === 0) {
      setErrorMsg('Please enter start time');
      return false;
    }

    if (endTime.length === 0) {
      setErrorMsg('Please enter end time');
      return false;
    }
    
    if (doctorId.length === 0 || chamberId.length === 0 || selectedDay.length === 0) {
      setErrorMsg('Something goes wrong, please refresh and try again!');
      return false;
    }
    // console.log(doctorId)
    // console.log(chamberId)
    // return false;
    // console.log(selectedDay)

    const post_data = {
      time_from: startTime,
      time_to: endTime,
      doctor_id: doctorId,
      chamber_id: chamberId,
      day_name: selectedDay
    };

    fetch(`${BASE_URL}doctorSchedule/save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(post_data)
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then(data => {
      // console.log('Success:', data);
      // Handle success logic here
      // console.log(data.last_id);

      const post_data = {
        id: data.last_id,
        time_from: startTime,
        time_to: endTime,
        doctor_id: doctorId,
        chamber_id: chamberId,
        day_name: selectedDay
      };

      onCreate(post_data);
    })
    .catch(error => {
      console.error('Error:', error);
      // Handle error logic here
    });
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <TimeInput label="Start Time" value={startTime} onChange={handleStartTimeChange} />
        <TimeInput label="End Time" value={endTime} onChange={handleEndTimeChange} />
        <button
          className="bg-pale-green-500 text-white py-2 px-4 rounded hover:bg-pale-green-300"
          onClick={handleAddButtonClick}
        >
          Add
        </button>
      </div>
      { errorMsg.length > 0 &&
        <div className='bg-red-100 text-red-700 p-3'>{errorMsg}</div>
      }
    </div>
  );
};

export default TimeForm;
