import React, { useState } from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import { cancelOpd } from '../api/cancelOpd';

const OpdCancelModal = ({isOpen, onClose, data, onUpdate}) => {
  const [reasonForCancellation, setReasonForCancellation] = useState("");
  const [buttonDisable, setButtonDisable] = useState(true);

  if (!data) return;

  function handleChange(value) {
    if (value.length > 3) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
    setReasonForCancellation(value);
  }

  const submit = async (e) => {
    e.preventDefault();
    // console.log(reasonForCancellation)
    const res = await cancelOpd(data.id, reasonForCancellation);
    if (res.status === 200) {
      onUpdate();
    }
  }

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? 'visible' : 'invisible'
      }`}
    >
      {/* Black shadow */}
      <div
        className={`fixed inset-0 bg-black opacity-50 ${
          isOpen ? 'visible' : 'invisible'
        }`}
        onClick={onClose}
      ></div>

      <div className="bg-white p-5 rounded shadow-md relative t-0 w-full md:w-1/2 max-h-full overflow-y-auto">
        <div className="flex justify-between">
          <h2 className="text-xl font-bold mb-4">Cancel appointment of { data.m_name }</h2>
          <span onClick={onClose} className="link pt-0">
            <AiOutlineClose size={23} />
          </span>
        </div>

        <div className="w-full">
          <form action="">

            <div className="flex flex-wrap mb-5">
              <div className='w-full px-4 mt-3'>
                <label htmlFor="">Reason for cancellation
                  <span className='text-red-500'>*</span> (required)</label>
                <input
                  type="text"
                  name="reason_for_cancellation"
                  value={reasonForCancellation}
                  className="w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none"
                  onChange={(e) => handleChange(e.target.value)}
                />
              </div>
            </div>

            <div className='flex justify-between'>
              <button className="mt-4 px-4 py-2 text-pale-green-700 rounded"
                onClick={onClose}
                tabIndex={-1}
              >
                Close
              </button>
              <button className={`mt-4 px-4 py-2 rounded focus:bg-pale-green-300 ${
                  buttonDisable
                    ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
                    : 'bg-pale-green-500 text-white'
                }`}
                onClick={submit}
                disabled={buttonDisable}
                >
                Cancel appointment
              </button>
            </div>
            
          </form>
        </div>
      </div>

    </div>
  );
};

export default OpdCancelModal;
