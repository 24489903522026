import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import AmbulanceTable from './layouts/AmbulanceTable';
import { findDistrictByid, fetchStates, searchAll } from './api/AmbulanceApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Ambulance() {

	useEffect(() => {
		document.title = "Ambulance | Impilo";
	});

	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [stateId, setStateId] = useState("");
	const [districtId, setDistrictId] = useState("");
	const [pincode, setPincode] = useState("");
	const [locality, setLocality] = useState("");
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
	const [ambulanceList, setAmbulanceList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchDistricts = async (stateId) => {
		let res = await findDistrictByid(stateId);
    setDistricts(res);
	};
	
	useEffect(() => {
  
    // Function to fetch data from the API
    const getAllStates = async () => {
			let res = await fetchStates();
			setStates(res);
    };

		getAllStates();
	}, []);

	const findDistrictByStateId = (id) => {
		fetchDistricts(id);
		setStateId(id);
	}
	
	const handleCreateModal = (value) => {
    setIsCreateModalOpen(value);
	};
	
	const search = async () => {
		if (stateId.length === 0 && districtId.length === 0
			&& pincode.length === 0 && locality.length === 0) {
			toast.error("Please select atleast one filter!");
			return false;
		}
		setIsLoading(true);
		let res = await searchAll(stateId, districtId, pincode, locality);
    setAmbulanceList(res);
		setIsLoading(false);
	}

	return (
		<div className='flex'>			
			<Sidebar />

			<div className='w-10/12 p-5'>
				<div className='flex flex-col'>

					{/* OPD TITLE ON THE LEFT */}
					<div className='flex w-full mb-3'>
						<div className='w-10/12'>
							<p className='text-2xl font-medium'>Ambulance master data</p>
						</div>
						<div className="w-2/12 flex justify-end">
							<button
								className='px-4 py-3 text-sm font-medium text-white bg-pale-green-500 rounded-md hover:bg-pale-green-600 focus:outline-none focus:ring focus:ring-pale-green-300'
								onClick={() => {
									setIsCreateModalOpen(true);
								}}
							>
								Add new ambulance
							</button>
						</div>
					</div>

					<div className='flex flex-wrap justify-between items-center'>
						{/* THE OTHER FILTER AND SEARCH BUTTON ON THE RIGHT  */}
						<div className="w-full flex flex-wrap">

							<div className='flex flex-col w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2'>
								<label htmlFor="">State</label>
								<select 
                  name="state_id"
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={stateId}
									onChange={(e) => findDistrictByStateId(e.target.value)}
									>
									<option value="">- select -</option>
                  {/* Populate the options from the states data */}
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.state_name}
                    </option>
                  ))}
                </select>
							</div>

							<div className='flex flex-col w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2'>
								<label htmlFor="">District</label>
								<select 
                  name="district_id"
                  className='w-full px-3 py-2 border-solid border-2 border-slate-300 rounded-md focus:border-pale-green-500 focus:outline-none'
                  value={districtId}
									onChange={(e) => setDistrictId(e.target.value)}
								>
									<option value="">- select -</option>
                  {/* Populate the options from the districts data */}
                  {districts.map((districts) => (
                    <option key={districts.id} value={districts.id}>
                      {districts.district_name}
                    </option>
                  ))}
                </select>
							</div>

							<div className='flex flex-col w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2'>
								<label htmlFor="">&nbsp;</label>
								<span className='mt-2'>OR</span>
							</div>

							<div className='flex flex-col w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2'>
								<label htmlFor="">Pincode</label>
								<input
									type='text'
									className='font-medium h-10 border border-pale-green-300 hover:border-pale-green-500 focus:outline-none focus:border-pale-green-500 rounded p-1'
									placeholder='pincode'
									value={pincode}
									onChange={(e) => setPincode(e.target.value)}
								/>
							</div>

							<div className='flex flex-col w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2'>
								<label htmlFor="">&nbsp;</label>
								<span className='mt-2'>OR</span>
							</div>

							<div className='flex flex-col w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2'>
								<label htmlFor="">Locality</label>
								<input
									type='text'
									className='font-medium h-10 border border-pale-green-300 hover:border-pale-green-500 focus:outline-none focus:border-pale-green-500 rounded p-1'
									placeholder='locality'
									value={locality}
									onChange={(e) => setLocality(e.target.value)}
								/>
							</div>

              <div className='flex flex-col w-1/12'>
                <label htmlFor="">&nbsp;</label>
                <button
                  className='ml-2 px-3 py-2 text-md font-medium text-white bg-pale-green-500 rounded hover:bg-pale-green-600 focus:outline-none focus:ring focus:ring-pale-green-300'
                  onClick={() => {
                    search();
                  }}
                  >
                  Search
                </button>
              </div>
						</div>
					</div>
				</div>

				<AmbulanceTable 
					dataList={ambulanceList} 
					isCreateModalOpen={isCreateModalOpen}
					isCreateModalClose={handleCreateModal}
					isLoading={isLoading}
				/>

				<ToastContainer
					position="bottom-left"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="light"
				/>
			</div>
		</div>
	);
}

export default Ambulance;