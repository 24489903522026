import React, { useCallback, useEffect, useState } from 'react';
import Sidebar from '../../components/Sidebar';
import { useParams } from 'react-router-dom';
import authenticatedServiceInstance from '../../services/AuthenticatedService';
import { ToastContainer, toast } from 'react-toastify';
import AbsentModal from './AbsentModal';
import {BiTrash} from 'react-icons/bi'; 
import RemoveConfirmationModal from '../../components/RemoveConfirmationModal'; // Import the confirmation modal
import 'react-toastify/dist/ReactToastify.css';

function AbsentManager() {

	const { doctorId, chamberId, doctorName, chamberName } = useParams();
	const [absent, setAbsent] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selecttedToRemove, setSelectedToRemove] = useState(null);
	const [showConfirmation, setShowConfirmation] = useState(false);

	const handleResponse = (value) => {
		// console.log(value)
		if (value === 'save') {
			toast.success("Successfully saved");
		} else if (value === 'error') {
			toast.error("Error occured");
		} else {
			toast.error("Something goes wrong!");
		}
	}

	const handleUpdateList = (updatedList) => {
    const updatedIndex = absent.findIndex(abs => abs.id === updatedList.id);
		// console.log(updatedIndex)
		if (updatedIndex === -1) {
			getAbsent();
		}
	}

	const getAbsent = useCallback(async () => {
		const BASE_URL = process.env.REACT_APP_BASE_URL;
		const token = authenticatedServiceInstance.getToken();

		try {
			const response = await fetch(`${BASE_URL}/absentManager/getAbsent?doctor_id=${doctorId}&chamber_id=${chamberId}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setAbsent(data);
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
	},[doctorId, chamberId]);

	useEffect(() => {
		getAbsent();
	}, [ getAbsent ]);

	// HANDLE MODALS
	const handleOpenCreateModal = () => {
    setIsModalOpen(true);
	};
	
	const handleCloseModal = () => {
    setIsModalOpen(false);
  };
	// END HANDLE MODALS

	const showRemoveConfirmation = (doctor) => {
		setSelectedToRemove(doctor);
		setShowConfirmation(true);
	};

	const hideRemoveConfirmation = () => {
		setSelectedToRemove(null);
		setShowConfirmation(false);
	};

	const handleRemove = async () => {
		if (!selecttedToRemove) return;

		const BASE_URL = process.env.REACT_APP_BASE_URL;
		const token = authenticatedServiceInstance.getToken();

		try {
			const response = await fetch(`${BASE_URL}/absentManager/remove/${selecttedToRemove.id}`, {
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.ok) {
				// Remove the chamber from the list
				const updatedList = absent.filter(abs => abs.id !== selecttedToRemove.id);
				setAbsent(updatedList);
				hideRemoveConfirmation(); // Close the confirmation modal
			} else {
				alert(response.res);
				console.error('Failed to remove doctor');
			}
		} catch (error) {
			alert(error);
			console.error('Error while removing doctor:', error);
		}
	};

	return (
		<div className='flex'>
			<Sidebar />
			<div className='w-full pt-1 pb-10 border-l-2 border-r-2'>
				<div className='flex flex-col justify-between'>
					<div className='flex flex-col w-full p-3'>
						<div className='font-semibold mb-3'>Absent Manager</div>
						<div className='flex'>
							<div className='text-xl font-semibold'> {doctorName} - { chamberName } </div>
							<div className='ml-auto'>
								<button
									className='px-4 py-2 text-sm font-medium text-white bg-pale-green-500 rounded-md hover:bg-pale-green-600 focus:outline-none focus:ring focus:ring-pale-green-300'
									onClick={handleOpenCreateModal}
								>
									Add absent
								</button>
							</div>
						</div>
					</div>

					<table className="min-w-full bg-white table-auto border-collapse">
						<thead>
							<tr className=' sticky top-0'>
								<th className="px-4 border-b text-sm font-medium text-slate-500 rounded-tl-xl text-left">SL</th>
								<th className="px-4 border-b text-sm font-medium text-slate-500 rounded-tl-xl text-left">Absent date</th>
								<th></th>
							</tr>
						</thead>

						<tbody>
							{ isLoading && Array.from({ length: 2 }, (_, index) => (
								<tr key={index}>
									<td colSpan={3} className='pt-2'>
										<div className="shimmer"></div>
									</td>
								</tr>
							))}
							
							{ absent.length === 0 && (
								<tr>
									<td colSpan={3} className='pt-2'>
										<div className="text-xs text-center text-gray-500">No absent record found</div>
									</td>
								</tr>
							)}
							{absent.map((abs, index) => (
								<tr key={abs.id} className='border-b hover:bg-slate-100'>
									<td className='py-2 px-4 w-2 text-xs'>{ index + 1 }</td>
									<td className='py-2 px-4 text-xs text-start'>
										{abs.absent_date}
									</td>
									<td className='flex justify-end'>
										<BiTrash
											size={23}
											className="mr-5 text-red-600 cursor-pointer"
											onClick={() => showRemoveConfirmation(abs)}
										/>
									</td>
								</tr>
							))}
						</tbody>

					</table>
				</div>
			</div>

			<AbsentModal
				isOpen={isModalOpen} 
				onClose={handleCloseModal} 
				response={handleResponse}
				doctorId={doctorId}
				chamberId={chamberId}
				onUpdate={handleUpdateList}
			/>
			<ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
			/>
			
			<RemoveConfirmationModal
				value="Absent date"
        isOpen={showConfirmation}
        onCancel={hideRemoveConfirmation}
        onConfirm={handleRemove}
      />
		</div>
	);
}

export default AbsentManager;