import Cookies from 'js-cookie';

class AuthenticatedService {
    registerSuccessfulLogin(token, refreshToken) {
        const expirationDate = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        Cookies.set('token', token, { expires: expirationDate, secure: true, httpOnly: false });
        Cookies.set('refresh_token', refreshToken, { expires: expirationDate, secure: true, httpOnly: false });
    }

    isUserLoggedIn() {
        const token = this.getToken();
        return !!token;
    }

    getToken() {
        return Cookies.get("token");
    }

    getRefreshToken() {
        return Cookies.get("refresh_token");
    }

    removeTokens() {
        Cookies.set("token", "");
        Cookies.set("refresh_token", "");
        Cookies.remove("token", { secure: true });
        Cookies.remove("refresh_token", { secure: true });
    }

    hasTokenExpired() {
        const token = this.getToken();
        if (!token) {
            return true; // Token is missing, consider as expired
        }

        const tokenPayload = token.split('.')[1];
        const decodedTokenPayload = atob(tokenPayload);
        const tokenData = JSON.parse(decodedTokenPayload);

        const expirationTimestamp = tokenData.exp;
        if (!expirationTimestamp) {
            return true; // If no expiration timestamp, consider token as expired
        }

        const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix timestamp format
        return expirationTimestamp <= currentTime;
    }
}

const authenticatedServiceInstance = new AuthenticatedService();
export default authenticatedServiceInstance;